import * as React from 'react'
const SvgDialMax = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="dial-max"
    className="svg-inline--fa fa-dial-max fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 88.93"
    {...props}
  >
    <path
      fill="currentColor"
      d="M72.3 66.63a5.5 5.5 0 0 0-.5-.72q-9.26-9.28-18.53-18.54a4.22 4.22 0 0 0-5-1.09 4.08 4.08 0 0 0-1.69 6.07 7.54 7.54 0 0 0 .89 1.07l18.39 18.39c.19.19.41.33.63.51a27.76 27.76 0 0 1-36.11-2.74 27.35 27.35 0 0 1-.11-39 27.41 27.41 0 0 1 39.05-.51 26.44 26.44 0 0 1 8.48 18.2 28.34 28.34 0 0 1-5.5 18.36ZM55.51 5.53a5.51 5.51 0 1 1-11 0 5.51 5.51 0 1 1 11 0ZM22.15 16.66a5.36 5.36 0 0 1-5.55 5.54 5.34 5.34 0 0 1-5.48-5.54 5.35 5.35 0 0 1 5.55-5.54 5.35 5.35 0 0 1 5.48 5.54Zm66.73-.03a5.52 5.52 0 1 1-11 .06 5.52 5.52 0 1 1 11-.06ZM11 50a5.36 5.36 0 0 1-5.49 5.53 5.54 5.54 0 0 1 0-11.08A5.36 5.36 0 0 1 11 50Zm89 0a5.52 5.52 0 1 1-5.47-5.54A5.36 5.36 0 0 1 100 50ZM22.15 83.39a5.36 5.36 0 0 1-5.54 5.54 5.54 5.54 0 0 1 .05-11.08 5.36 5.36 0 0 1 5.49 5.54Zm55.7.03a5.52 5.52 0 1 1 5.51 5.51 5.36 5.36 0 0 1-5.51-5.51Z"
    />
  </svg>
)
export default SvgDialMax
