import React from 'react'
import PropTypes, { oneOf, string } from 'prop-types'
import { Link } from 'react-router-dom'

function SectionFooterLink({ id, link, type, classList, linkClassList }) {
  return (
    <>
      {/*
        -----------------------------------------
        SECTION FOOTER LINK
        -----------------------------------------
      */}

      {link && (
        <div
          className={`section__footer--link${classList ? ' ' + classList : ''}`}
          id={id}
        >
          <Link
            to={link.path}
            className={`link__btn--${type}${
              linkClassList ? ' ' + linkClassList : ''
            }`}
          >
            {link.text}
          </Link>
        </div>
      )}
    </>
  )
}

SectionFooterLink.defaultProps = {
  link: {
    text: 'Nous contacter',
  },
  type: 'primary',
}

SectionFooterLink.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.shape({
    path: string,
    text: string,
  }).isRequired,
  type: oneOf(['primary', 'secondary', 'tertiary']),
}

export default SectionFooterLink
