import {
  Facebook,
  Whatsapp,
  Instagram,
  Messenger,
  Tiktok,
} from 'react-bootstrap-icons'
import { useEffect, useRef } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import ContactsHeaderImg from '../../assets/img/contactsheaderimg.png'
import InputsField from '../../components/InputsField'
import SelectsField from '../../components/SelectsField'
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react'
import SvgSpinnerThird from '../../assets/dist/SpinnerThirdIcon'
import SvgXmark from '../../assets/dist/XMarkIcon'
import PopUpMessage from '../../components/PopUpMessage'
import { ShakeButton } from '../../utils/functions'
import MainSectionHeader from '../../components/Sections/MainSectionHeader'
import MainSection from '../../components/Sections/MainSection'
import MainSectionBody from '../../components/Sections/MainSectionBody'
// import { emailjs } from '@emailjs/browser'

function Contacts() {
  const { interestOption } = useParams()
  const [isShowned, setIsShowned] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const form = useRef()
  const defaultValues = {
    name: '',
    email: '',
    interest: interestOption ? interestOption : '',
    message: '',
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  })

  const sendEmail = () => {
    // e.preventDefault()

    !isShowned &&
      emailjs
        .sendForm(
          'sfzContactServiceId',
          'contactFormId',
          form.current,
          'WvL6uEotx922Gybmd'
        )
        .then(
          (result) => {
            if (result.text === 'OK' && !isShowned) {
              document.querySelector('#popup').classList.add('opened')
              handleReset()
              setIsShowned(true)
              setIsSubmitting(false)
              console.log(isSubmitting, ' ---> is submitting 2')
            }
          },
          (error) => {
            console.log(error.text)
          }
        )
  }

  const handleIsShowned = () => {
    document.querySelector('#popup').classList.remove('opened')
  }

  useEffect(() => {
    let timeInterval = ShakeButton()

    return () => clearInterval(timeInterval)
  })

  const handleScrollBottom = () => {
    const scrollHeight =
      document.getElementById('contactsHeader').offsetHeight - 81

    window.scrollTo({ top: scrollHeight })
  }

  /**
   * handle submit contact us form
   * @param {HTMLObjectElement} e
   */
  const onSubmit = (data) => {
    document.querySelector('#popup').classList.add('opened')
    setIsSubmitting(true)
    console.log(isSubmitting, ' ----> is submitting 1')
    sendEmail()
    //
  }

  /**
   * handle reset contact us form
   * @param {HTMLObjectElement} e
   */
  const handleReset = () => {
    setIsShowned(false)
    setIsReset(true)
    for (const key in defaultValues) {
      if (Object.hasOwnProperty.call(defaultValues, key)) {
        if (key !== 'interest') setValue(key, '')
      }
    }
    // console.log(isReset, ' ----> is reset')
  }

  // /**
  //  * handle changes of the inputs of the form
  //  * @param {HTMLDataElement} event
  //  */
  // const handleInptChange = (event) => {
  //   const target = event.target
  //   const value = target.type === 'checkbox' ? target.checked : target.value
  //   const name = target.name

  //   setFormData({ ...formData, [name]: value })
  //   console.log(formData)
  // }

  return (
    <>
      <header
        id="contactsHeader"
        className="contacts__header page-header main-header"
      >
        <div className="page-header__container row-cols-1">
          <div className="page-header__body col-md-5">
            <h1 className="type-contact page-header__body--title h1">
              Nous contacter
            </h1>
            <p className="page-header__body--description subtile">
              Contactez-nous par appel téléphonique, par Whatsapp, Facbook, ou
              Instagram. Vous pouvez tout de même choisir de nous écrire
              directement ici. Notre équipe vous répond.
            </p>
            <span
              className="scroll-down-box scroll-down-btn"
              id="HeaderScrollBtn"
              onClick={handleScrollBottom}
            >
              Commencer
            </span>
          </div>
          <div className="page-header__img col-md-7">
            <img
              src={ContactsHeaderImg}
              alt="En cours"
              className="img-fluid item-image-img"
            />
          </div>
        </div>
      </header>

      <main>
        {/*  -- 
      -----------------------------------------
      MAIL FORM SECTION
      -----------------------------------------
      -- */}

        <MainSection id="contactSection">
          <MainSectionHeader
            id="contactSectionHeader"
            title="Laissez-nous votre message !"
            classList="section-dark sfz--bg-gradient-blue"
            description={
              <>
                <strong>
                  Nous sommes là pour répondre à toutes vos préoccupations.
                </strong>{' '}
                <br />
                Laissez-nous votre message et nous serons ravis de vous répondre
                dans les plus brefs délais ! <br />
                <br /> <strong>Remplissez le formulaire ci-dessous.</strong>
              </>
            }
          />
          <MainSectionBody
            id="contactSetcionBody"
            containerClassList="container container-fluid"
          >
            <div className="card--grid contact-box" id="contactForm">
              <div className="section__body--text contact-box__aside">
                <h3 className="description contact-box__aside--title">
                  Remplissez le formulaire de contacts et soumettez le.
                </h3>
              </div>
              <div className="contact-box__form">
                <div className="popup" id="popup">
                  <div className="popup__button">
                    {!isSubmitting && (
                      <button
                        className="popup__button--close btn"
                        onClick={handleIsShowned}
                      >
                        <SvgXmark />
                      </button>
                    )}
                  </div>
                  <div className="popup--container">
                    {isSubmitting ? <SvgSpinnerThird /> : <PopUpMessage />}
                  </div>
                </div>
                <Form
                  ref={form}
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <InputsField
                        type="text"
                        name="name"
                        register={register('name', {
                          required: 'Nom(s) obligatoire(s)',
                          minLength: {
                            value: 4,
                            message: 'Minimum 4 lettres',
                          },
                          pattern: {
                            value: /^[A-Za-z0-9\s]+$/i,
                            message: 'Que des lettres alphabétiques',
                          },
                        })}
                      >
                        Entrez votre Nom
                      </InputsField>
                      {errors.name && <span>{errors.name.message}</span>}
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <InputsField
                        type="email"
                        name="email"
                        register={register('email', {
                          required: 'Adresse mail obligatoire',
                          pattern: {
                            // eslint-disable-next-line no-useless-escape
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$$/i,
                            message: 'doit être une adresse mail',
                          },
                        })}
                      >
                        Entrez votre adresse mail
                      </InputsField>
                      {errors.email && <span>{errors.email.message}</span>}
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-3">
                    <SelectsField
                      label="Mon centre d'intérêt"
                      name="interest"
                      interestOption={interestOption ? interestOption : ''}
                      isReset={isReset}
                      register={register('interest', {
                        required: "Vous devez choisir un centre d'intérêt",
                      })}
                    >
                      <option value="">Choisissez un centre d'intérêt</option>
                      <option value="1">Cours d'allemand</option>
                      <option value="2">
                        Mündliches Training (séances d’exercices)
                      </option>
                      <option value="3">
                        Préparation aux examens de langue
                      </option>
                      <option value="4">Cours de vacances</option>
                      <option value="5">
                        Contrat de formation en Allemagne
                      </option>
                      <option value="6">Contrat de travail en Allemagne</option>
                      <option value="7">Cours pour enfant</option>
                      <option value="8">Autres raisons</option>
                    </SelectsField>
                    {errors.interest && <span>{errors.interest.message}</span>}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <InputsField
                      as="textarea"
                      name="message"
                      style={{ height: '160px' }}
                      register={register('message', {
                        required: 'Message obligatoire',
                        pattern: {
                          value:
                            // eslint-disable-next-line no-useless-escape
                            /^[\w!-\s\.'àéèêâäëïöüù,;:?%/\\*$€@ç(&+)]+$/i,
                          message:
                            'Certains caractères ne sont pas acceptés !!!',
                        },
                      })}
                    >
                      Votre message
                    </InputsField>
                    {errors.message && <span>{errors.message.message}</span>}
                  </Form.Group>
                  {isShowned ? (
                    <button
                      className="btn disabled btn-secondary"
                      type="submit"
                      disabled
                    >
                      Message envoyé
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Envoyer le message
                    </button>
                  )}

                  <button
                    className="btn btn-secondary btn-reset"
                    type="reset"
                    onClick={handleReset}
                  >
                    Réinitialiser
                  </button>
                  {/* <p>{JSON.stringify(formData)}</p> */}
                </Form>
              </div>
            </div>
          </MainSectionBody>
        </MainSection>

        {/*  -- 
      -----------------------------------------
      SOCIAL NETWORK SRCTION
      -----------------------------------------
      -- */}

        <MainSection id="socialNetworkSection" classList="social-network">
          <MainSectionHeader
            id="socialNetworkSectionHeader"
            title="Nous sommes sur les réseaux sociaux"
            description={
              <>
                <strong>
                  Retrouvrez nous sur les réseaux sociaux pour suivre
                  l'actualité de Sprachfluss Zentrum.
                </strong>{' '}
                <br />
                Nous y publions régulièrement toutes les activités qui se
                déroulent dans nos locaux et aussi les opportunités de voyages à
                l'horizon <br />
                <br />{' '}
                <strong>
                  Voici ci-dessous les liens vers nos différents réseaux
                  sociaux.
                </strong>
              </>
            }
          />
          <MainSectionBody
            id="socialNetworkSectionBody"
            containerClassList="container container-fluid social-network--container"
          >
            <div className="card--grid">
              <div className="social-network--elt">
                <span className="elt--icon">
                  <Whatsapp
                    color="#25d366"
                    size="5em"
                    className="icon whatsapp"
                    id="whatsapp"
                  />
                </span>
                <Link to={'#'} className="social-network--link">
                  WhatsApp
                </Link>
              </div>
              <div className="social-network--elt">
                <span className="elt--icon">
                  <Facebook
                    color="#4169e1"
                    size="5em"
                    className="icon facebook"
                    id="facebook"
                  />
                </span>
                <Link to={'#'} className="social-network--link">
                  Facebook
                </Link>
              </div>
              <div className="social-network--elt">
                <span className="elt--icon">
                  <Instagram
                    color="#ca2d8e"
                    size="5em"
                    className="icon instagram"
                    id="instagram"
                  />
                </span>
                <Link to={'#'} className="social-network--link">
                  Instagram
                </Link>
              </div>
              <div className="social-network--elt">
                <span className="elt--icon">
                  <Messenger
                    color="#4467f6"
                    size="5em"
                    className="icon messenger"
                    id="messenger"
                  />
                </span>
                <Link to={'#'} className="social-network--link">
                  Messenger
                </Link>
              </div>
              <div className="social-network--elt">
                <span className="elt--icon">
                  <Tiktok
                    color="#f53c3c"
                    size="5em"
                    className="icon tiktok"
                    id="tiktok"
                  />
                </span>
                <Link to={'#'} className="social-network--link">
                  Tik-Tok
                </Link>
              </div>
            </div>
          </MainSectionBody>
        </MainSection>
      </main>
    </>
  )
}

export default Contacts
