import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SvgTruckContainer from '../../assets/dist/TruckContainerIcon'
import SvgWheatSlash from '../../assets/dist/WheatSlashIcon'
import SvgChampagneGlasses from '../../assets/dist/ChampagneGlassessIcon'
import SvgBusiness from '../../assets/dist/BusinessIcon'
import SvgScreenUsers from '../../assets/dist/ScreenUsersIcon'
import SvgGraduationCap from '../../assets/dist/GraduationIcon'
import SvgFamilyIcon from '../../assets/dist/FamilyIcon'
import SvgPlane from '../../assets/dist/PlaneIcon'
import SvgDialMax from '../../assets/dist/DialMaxIcon'

// const german = Math.random().toString(36).substring(2, 9)

export const germanCoursesOffersData = [
  {
    id: 'german-courses',
    image: <FontAwesomeIcon icon={solid('check-circle')} size="2x" />,
    description: (
      <>
        Cours de langue allemande: niveau <strong>A1, A2, B1, B2, C1</strong>.
      </>
    ),
    link: { to: 'study-trips', text: "Plus d'info sur le cours" },
  },
  {
    id: 'speaking-courses',
    image: <FontAwesomeIcon icon={regular('check-circle')} size="2x" />,
    description:
      'Mündliches Training (séances d’exercices permettant d’améliorer la capacité oratoire)',
    link: {
      to: 'speaking-courses',
      text: "Plus d'info sur le training",
    },
  },
  {
    id: 'preparing-courses',
    image: <FontAwesomeIcon icon={solid('check-circle')} size="2x" />,
    description: (
      <>
        Préparation aux différents examens de langue (<strong>ZDAF (B1)</strong>
        , <strong>MITTELSTUFE (B2)</strong>, <strong>TESTDAF (C5)</strong>)
      </>
    ),
    link: {
      to: 'exam-preparation',
      text: "Plus d'info sur la préparation",
    },
  },
  {
    id: 'hollidays',
    image: <FontAwesomeIcon icon={regular('check-circle')} size="2x" />,
    description:
      'Cours de vacances et de remise à niveau pour les élèves, étudiants, travailleurs, voyageurs...',
    link: {
      to: 'refresher-courses',
      text: "Plus d'info sur les cours",
    },
  },
]

export const trainingOffersData = [
  {
    id: 'nurse-training-contracts',
    image: <FontAwesomeIcon icon={solid('syringe')} size="2x" />,
    description: (
      <>
        Votre contrat de formation en soins infimiers et médécine{' '}
        <strong>en Allemagne</strong> vous attend.
      </>
    ),
    link: { to: 'nurse-training-contracts', text: "Plus d'information" },
  },
  {
    id: 'driving-training-contracts',
    image: <SvgTruckContainer />,
    description: (
      <>
        Obtenez un contrat de formation pour être chauffeur de poids lourds{' '}
        <strong>en Allemagne</strong>.
      </>
    ),
    link: { to: 'driving-training-contracts', text: "Plus d'information" },
  },
  {
    id: 'more-training-contracts',
    image: <FontAwesomeIcon icon={solid('file-signature')} size="2x" />,
    description: (
      <>
        Bien d'autres contrats de formation en Allemagne sont disponible avec{' '}
        <strong>Sprachfluss Zentrum</strong> ▶
      </>
    ),
  },
]

export const jobsOffersData = [
  {
    id: 'nurse-job-contracts',
    image: <FontAwesomeIcon icon={solid('syringe')} size="2x" />,
    description: (
      <>
        Nous offrons des contrats de travail dans le domaine de l'infimerie et
        de la médécine.
      </>
    ),
    link: { to: 'nurse-job-contracts', text: "Plus d'information" },
  },
  {
    id: 'driving-job-contracts',
    image: <FontAwesomeIcon icon={regular('id-card')} size="2x" />,
    description: (
      <>
        Vous avez un <strong>permis de condiute</strong> ? <br />
        devenez grâce à <strong>Sprachluss</strong> un chauffeur de poids lourds{' '}
        <strong>en Allemagne</strong>.
      </>
    ),
    link: { to: 'driving-job-contracts', text: "Plus d'information" },
  },
  {
    id: 'aupair-job-contracts',
    image: <FontAwesomeIcon icon={solid('broom')} size="2x" />,
    description: (
      <>
        <strong>Au-pair-Mädchen?</strong> <br />
        <em>filles Au pair ?</em> <br />
        votre contrat de travail en Allemagne vous attend.
      </>
    ),
    link: { to: 'aupair-job-contracts', text: "Plus d'information" },
  },
  {
    id: 'free-job-contracts',
    image: <SvgWheatSlash />,
    description: (
      <>
        Choisissez de faire du volontariat <strong>en Allemagne</strong> et
        obtenez votre contrat de travail grâce à nos partenaires.
      </>
    ),
    link: { to: 'free-job-contracts', text: "Plus d'information" },
  },
  {
    id: 'more-job-contracts',
    image: <FontAwesomeIcon icon={solid('file-contract')} size="2x" />,
    description: (
      <>
        Et ce n'est pas tout ! <strong>Sprachfluss</strong> vous offre encore
        plus de contrats de travail en Allemagne.
      </>
    ),
  },
]

export const travelReasonsData = [
  {
    id: 'immigration',
    image: <SvgFamilyIcon />,
    description: 'Rejoindre votre famille en Allemagne,',
  },
  {
    id: 'studies-purshue',
    image: <SvgGraduationCap />,
    description: 'Suivre une formation rémunérée,',
  },
  {
    id: 'paid-training',
    image: <SvgScreenUsers />,
    description: 'Suivre une formation rémunérée,',
  },
  {
    id: 'business-job',
    image: <SvgBusiness />,
    description: 'Travailler ou faire des affaires en Allemagne,',
  },
  {
    id: 'visit-vaccation',
    image: <SvgChampagneGlasses />,
    description: 'Des vaccances, rendre visite ou faire du tourisme,',
  },
]

export const kidsOptionsData = [
  {
    id: 'school-level',
    image: <SvgDialMax />,
    description:
      'Relever leur niveau scolaire leur permettant d’obtenir de meilleures résultats à l’école,',
  },
  {
    id: 'travel-opportunity',
    image: <SvgPlane />,
    description:
      "Se préparer à d'éventuelles opportunités de voyage pour l’Allemagne,",
  },
  {
    id: 'knowledge-improve',
    image: <FontAwesomeIcon icon={solid('language')} size="2x" />,
    description: 'Augmenter leurs connaissances en matière de langue,',
  },
]
