import LogoDark from '../../assets/img/sfzLogo-notext.png'
import SearchIcon from '../../assets/icons/search-b.svg'
import {
  LogoBox,
  LogoImg,
  NavBox,
  SearchIconImg,
  TopBarContainer,
} from './style'
import { Navbar as ReactNavbar, Nav } from 'react-bootstrap'
import { StyledLink } from '../../utils/style/Atoms'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import appColors from '../../utils/style/appColors'
import { NavLinks } from '../../data/NavLinks'

function NavbarComponent() {
  const LOGODEFAULTSIZE = 45
  const [logoSize, setLogoSize] = useState(LOGODEFAULTSIZE)
  const [shadow, setShadow] = useState({})
  const [bgColor, setBgColor] = useState('')

  const activeNavLink = {
    color: appColors.orange,
    borderBottom: '3px solid',
  }

  useEffect(() => {
    const topbarScrollDownStyle = {
      backgroundColor: `white`,
      boxShadow: `0 0.2em 0.5em rgba(32, 32, 32, 0.1)`,
    }
    document.addEventListener(
      'scroll',
      () => {
        if (window.pageYOffset > 0) {
          setLogoSize(30)
          setShadow(topbarScrollDownStyle)
        } else {
          setShadow({})
          setLogoSize(LOGODEFAULTSIZE)
        }
      },
      false
    )
  }, [logoSize, shadow])

  const handleShow = (e) =>
    window.pageYOffset <= 0 ? setBgColor(bgColor ? '' : 'white') : null

  return (
    <>
      <ReactNavbar
        id="topbar"
        fixed="top"
        expand="lg"
        className="topbar"
        style={{ backgroundColor: bgColor, ...shadow }}
      >
        <TopBarContainer className="" id="topbar--container">
          <LogoBox id="logoBox" className="">
            <StyledLink to="/" className="navbar-brand home-link" type="home">
              <LogoImg
                src={LogoDark}
                id="logo"
                size={logoSize}
                alt="Sprachfluss Zentrum"
                className="d-inline-block align-text-top m-auto"
              />
              {/* <strong className="m-auto logo-text d-none d-sm-inline d-md-none d-lg-inline">
                SPRACHFLUSS ZENTRUM
              </strong> */}
            </StyledLink>
          </LogoBox>

          {/* MENU BUTTON */}
          <ReactNavbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={(e) => handleShow(e)}
          />

          {/* END MENU BUTTON */}

          {/* NAV ELEMENT */}
          <NavBox
            className="justify-content-lg-end"
            id="navbarSupportedContent"
          >
            <Nav className="">
              {NavLinks.map(
                (
                  { path, text, itemClassName, linkClassName, disabled },
                  index
                ) => (
                  <Nav.Item className={itemClassName} key={`${path}${index}`}>
                    <Nav.Link
                      as={NavLink}
                      type="navbar"
                      to={path}
                      disabled={disabled}
                      className={linkClassName}
                      style={({ isActive }) =>
                        isActive ? activeNavLink : undefined
                      }
                    >
                      {text}
                    </Nav.Link>
                  </Nav.Item>
                )
              )}
              {/* <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Accueil
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/services"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Services
                </Nav.Link>
                <Nav.Item className="">
                  <Nav.Link
                    as={NavLink}
                    type="navbar"
                    to="/courses"
                    className="nav--link"
                    style={({ isActive }) =>
                      isActive ? activeNavLink : undefined
                    }
                  >
                    Courses
                  </Nav.Link>
                </Nav.Item>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/gallery"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Galeries
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/about-us"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  A propos
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/contacts"
                  id="contacts"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Contacts
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
            {/* NAVBAR ELEMENT */}

            {/* SEARCHBAR ELEMENT */}
            <button id="search" className="btn">
              <SearchIconImg
                src={SearchIcon}
                className="m-auto svgInject"
                alt="search icon"
                id="searchIcon"
              />
            </button>
            <div className="" id="searchbar-div"></div>
          </NavBox>
          {/* NAV ELEMENT */}
        </TopBarContainer>
      </ReactNavbar>
      <div className="" id="cloned--topbar"></div>
    </>
  )
}

export default NavbarComponent
