import React from 'react'
import PropTypes from 'prop-types'

function SectionBodyOne({ id, children }) {
  return (
    <>
      {/*
        -----------------------------------------
        SECTION BODY ONE
        -----------------------------------------
      */}
      <div className="section__body" id={id}>
        <div className="section__container container-lg container-fluid py-2">
          {/*  -- START THE FEATURETTES -- */}

          {children}
        </div>
      </div>
    </>
  )
}

SectionBodyOne.propTypes = {
  id: PropTypes.string.isRequired,
}

export default SectionBodyOne
