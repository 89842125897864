import React from 'react'
import { ThreeDotsVertical } from 'react-bootstrap-icons'
import CoursesPicture from '../../assets/img/sfz-cours.png'

import Sidebar from '../../components/Sidebar'
import FooterComponent from '../../components/Footers'
import ScrollToTop from '../../components/ScrollToTop'
import NavbarSticky from '../../components/NavbarSticky'
import { germanCourses } from '../../data/courses/coursesData'
import { MainCard } from '../../components/Cards'
import ComingSoon from '../../components/ComingSoon'
import SectionItemOne from '../../components/Sections/SectionItemOne'
import { Link } from 'react-router-dom'

export function loader({ params }) {}

const { highlights } = germanCourses.find(
  (course) => course.path === 'kids-youth'
)

// const highlights = studyTripData?.highlights

const sectionItemsData = [
  {
    id: 'st-itemOne',
    title: "Environnement d'Apprentissage Ludique",
    description: (
      <>
        Nos cours pour <strong>enfants et jeunes</strong> sont basés sur une
        approche ludique et interactive. Nous comprenons l'importance de
        maintenir leur intérêt et leur engagement tout au long du processus
        d'apprentissage. <br />
        <br />
        Nos instructeurs qualifiés utilisent des{' '}
        <strong>activités amusantes</strong>, des{' '}
        <strong>jeux et des exercices interactifs</strong> pour rendre
        l'apprentissage de la langue allemande passionnant.
      </>
    ),
  },
  {
    id: 'st-itemTwo',
    title: 'Maîtrise des Compétences Clés',
    description: (
      <>
        Nos instructeurs expérimentés vous guideront à travers les différentes
        sections de l'examen, en vous aidant à améliorer votre{' '}
        <strong>compréhension orale et écrite</strong>, votre{' '}
        <strong>expression orale et écrite</strong>, ainsi que vos compétences
        en <strong>grammaire</strong> et en <strong>vocabulaire</strong>. <br />
        <br />
        Vous apprendrez des stratégies efficaces pour répondre aux questions et
        pour gérer le temps de manière optimale.
      </>
    ),
  },
  {
    id: 'st-itemThree',
    title: 'Développement des Compétences Fondamentales',
    description: (
      <>
        Nos cours visent à{' '}
        <strong>développer les compétences fondamentales</strong> en langues, y
        compris la <strong>compréhension orale</strong>, la{' '}
        <strong>communication orale</strong>, la{' '}
        <strong>lecture et l'écriture</strong>. <br />
        <br />
        Nous adaptons notre approche en fonction de l'âge et du niveau de chaque
        groupe d'âge pour <strong>maximiser leur compréhension</strong> et leur
        participation.
      </>
    ),
  },
  {
    id: 'st-itemFour',
    title: "Cultivation d'une Base Solide",
    description: (
      <>
        Nous croyons que les <strong>bases solides</strong> sont essentielles
        pour un apprentissage réussi à long terme. <br />
        <br />
        Nos cours pour enfants et jeunes visent à établir une base solide en{' '}
        <strong>grammaire</strong>, en <strong>grammaire</strong> et en
        <strong>expression</strong>, ce qui leur permettra de progresser avec
        confiance dans leurs études futures.
      </>
    ),
  },
  {
    id: 'st-itemFive',
    title: 'Approche Holistique',
    description: (
      <>
        Au-delà de l'apprentissage linguistique, nos cours intègrent des
        éléments culturels pour <strong>enrichir leur compréhension</strong> de
        la langue allemande et de la culture germanophone. <br />
        <br />
        Des activités telles que <strong>des contes</strong>,{' '}
        <strong>des chansons</strong> et{' '}
        <strong>des projets artistiques</strong> encouragent une compréhension
        globale et approfondie.
      </>
    ),
  },
  {
    id: 'st-itemSix',
    title: 'Pourquoi Choisir Nos Cours pour enfants et jeunes ?',
    children: highlights ? (
      highlights.map((detail) => {
        return (
          <div
            key={`trip-${detail.id}`}
            className="card__container my-2 col-md-6 col-12"
          >
            <MainCard title={detail.title} text={detail.description} />
          </div>
        )
      })
    ) : (
      <ComingSoon />
    ),
  },
]

function KidsAndYouthCourses() {
  const handleSidebarShow = () => {
    document.getElementById('mainSidebar').style.left = '00px'
    document.getElementById('contentWrapper').style.left = '300px'
    document.getElementById('sidebarButton').style.display = 'none'
    document.getElementById('wrapperOverlay').style.display = 'block'
    document.body.style.overflow = 'hidden'
  }

  return (
    <>
      <ScrollToTop>
        <Sidebar mobile={true} />
        <main className="main-wrapper" id="contentWrapper">
          <div className="overlay" id="wrapperOverlay"></div>
          <NavbarSticky />
          <header
            id="kidsAndyouthCoursesHeader"
            className="blendmode-header main-header courses-header"
          >
            <div className="blendmode-header__img">
              <img
                src={CoursesPicture}
                alt="En cours"
                className="img-fluid item-image-img"
              />
              <div className="img__blendmode" />
            </div>
            <div className="blendmode-header__body">
              <div className="blendmode-header__body--title">
                <h1 className="blendmode-header__body--title-h1 h1 courses--detail__main-title">
                  Épanouissement Linguistique pour les Jeunes Esprits grâce à{' '}
                  <br />
                  Nos Cours Spécialement Conçus pour eux
                </h1>
              </div>
            </div>
          </header>

          <section className="sections__wrapper py-4" id="coursesSection">
            <div className="sections__container container-lg container-fluid">
              <Sidebar />
              <div
                className="sidebar__button link__btn--secondary"
                id="sidebarButton"
              >
                <ThreeDotsVertical
                  className="sidebar__button--show"
                  onClick={handleSidebarShow}
                />
              </div>

              {/*  -- 
              -----------------------------------------
              COURSE DETAILS SECTION
              -----------------------------------------
              -- */}

              <div className="course-details px-4" id="courseDetails" data-spy>
                <div className="section-header">
                  <h2 className="section__title section--h2 h2">
                    Nos Cours pour Enfants et Jeunes
                  </h2>
                  <p className="section__description text-center text-lg-start">
                    Chez <strong>SFZ</strong>, nous croyons que l'apprentissage
                    linguistique peut être une aventure amusante et passionnante
                    pour les enfants et les jeunes. <br />
                    Nos cours spécialement conçus pour ces jeunes esprits visent
                    à <strong>stimuler leur curiosité</strong>, à{' '}
                    <strong>développer leurs compétences linguistiques</strong>{' '}
                    et à leur offrir une{' '}
                    <strong>expérience d'apprentissage mémorable</strong>.{' '}
                  </p>
                  <p className="text-highlighted">
                    Voici comment nos cours pour enfants et jeunes peuvent les
                    aider à grandir et à s'épanouir :
                  </p>
                </div>

                <div className="section__body">
                  {sectionItemsData.map((item) => (
                    <SectionItemOne
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      description={item.description}
                    >
                      {item.children && (
                        <div className="course justify-content-center justify-content-lg-between">
                          {item.children}
                        </div>
                      )}
                    </SectionItemOne>
                  ))}
                </div>

                <div className="section__footer">
                  <div className="section__footer--container container-md container-fluid">
                    <p className="section__footer--text text-highlighted">
                      Rejoignez-nous pour une préparation réussie aux examens de
                      langue allemande. Nos cours spécialisés vous aideront à
                      maîtriser les compétences nécessaires pour obtenir des
                      résultats exceptionnels. <br />
                      <br />
                      <strong>
                        Embarquez dans une Aventure d'Apprentissage et de
                        Découverte en Allemagne
                      </strong>
                    </p>
                    <div className="section__footer--link">
                      <Link to={'contacts'} className="link__btn--primary">
                        Inscrivez-vous dès Aujourd'hui !
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent />
        </main>
      </ScrollToTop>
    </>
  )
}

export default KidsAndYouthCourses
