// import React from 'react'

import MbSIKLogo from '../../assets/img/Mb_SIK_logo.png'
import DPLogo from '../../assets/img/dp-logo.png'
import ServicesHeaderImg from '../../assets/img/aboutusheaderimg.png'
import logoDark from '../../assets/img/sfzLogo-notext.png'
import TargetImg from '../../assets/img/target.png'
import GroupImg from '../../assets/img/group-1.png'

import { useEffect } from 'react'
import { ShakeButton, randomBgColor } from '../../utils/functions'
import MainHeader from '../../components/Headers/MainHeader'
import MainSectionHeader from '../../components/Sections/MainSectionHeader'
import MainSection from '../../components/Sections/MainSection'
import MainSectionBody from '../../components/Sections/MainSectionBody'
import AboutUsCard from '../../components/Cards/AboutUsCard'
import Team from '../../components/team/Team'

function AboutUs() {
  // const [sidebarLeft, setSidebarLeft] = useState(0)
  // const [sidebarTop, setSidebarTop] = useState()

  useEffect(() => {
    let icons = document.querySelectorAll('.random-bg')
    randomBgColor(icons)

    let timeInterval = ShakeButton()

    // console.log(typeof icons)

    return () => clearInterval(timeInterval)
  })

  const handleScrollBottom = () => {
    const scrollHeight =
      document.getElementById('aboutusHeader').offsetHeight - 66

    window.scrollTo({ top: scrollHeight })
  }

  return (
    <>
      <MainHeader
        id="aboutusHeader"
        subtitle={'à propos de'}
        title={'SprachFluss Zentrum'}
        description={
          <>
            Tout savoir sur SFZ, notre raison d’être et mission, sur l’équipe,
            sur nos partenaires et nos différentes filiales....
          </>
        }
        className="aboutus__header page-header"
        headerContanierClassName={'row-cols-1'}
        headerBodyClassName={'col-md-5'}
        headerImgClassName={'col-md-7'}
        image={{
          src: ServicesHeaderImg,
          alt: 'about us header image',
          className: 'img-fluid item-image-img',
        }}
        handleScrollBottom={handleScrollBottom}
      />

      <main>
        {/*  -- 
      -----------------------------------------
      ABOUT US SECTION
      -----------------------------------------
      -- */}

        <MainSection id="aboutCompagny">
          <MainSectionHeader
            id="aboutCompagnyHeader"
            title={
              <>
                Découvrez Sprachfluss Zentrum <br />
                <strong>Votre Partenaire pour la Réussite en Allemagne</strong>
              </>
            }
            description={
              <>
                Apprenez-en davantage sur notre centre linguistique dédié à
                fournir des opportunités éducatives et professionnelles pour
                ceux qui aspirent à un avenir en Allemagne.
              </>
            }
            classList={'section-dark sfz--bg-gradient-orange'}
          />
        </MainSection>

        <section className="section">
          <div className="container container-fluid">
            <div className="section__body">
              <div className="card--grid">
                <AboutUsCard
                  id="aboutMission"
                  title={"Raison d'être"}
                  image={logoDark}
                  description={
                    <>
                      SFZ, Sprachfluss Zentrum, est un centre linguistique basé
                      au Cameroun, fondé avec la vision de faciliter l'accès à
                      l'Allemagne pour des opportunités d'études, de travail et
                      de développement professionnel. Depuis nos débuts, nous
                      nous sommes engagés à guider et soutenir nos apprenants
                      tout au long de leur parcours.
                    </>
                  }
                />
                <AboutUsCard
                  id="aboutMission"
                  title={'Mission / vocation'}
                  image={GroupImg}
                  description={
                    <>
                      Notre mission est de rendre les rêves de vie en Allemagne
                      accessibles à tous. Nous nous efforçons d'offrir des cours
                      de qualité, des opportunités de formation et des contrats
                      de travail adaptés aux besoins spécifiques de chaque
                      individu.
                    </>
                  }
                />
                <AboutUsCard
                  id="aboutChoice"
                  title={'Pourquoi le SFZ ?'}
                  image={TargetImg}
                  description={
                    <>
                      Choisir SFZ, c'est opter pour une expérience éducative et
                      professionnelle unique, axée sur vos aspirations
                      individuelles. Notre centre se distingue par une approche
                      personnalisée, une expertise et engagement envers la
                      réussite de nos apprenants, des partenariats solides avec
                      des institutions en Allemagne et un soutien continu pour
                      garantir une intégration réussie en Allemagne.
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </section>

        {/*  -- 
      -----------------------------------------
      OUR TEAM SECTION
      -----------------------------------------
      -- */}

        <MainSection id="aboutTeam">
          <MainSectionHeader
            id="aboutTeamHeader"
            title={"L'équipe SprachFluss Zentrum !"}
            description={
              <>
                Notre équipe dévouée est composée{' '}
                <strong>d'instructeurs qualifiés et de professionnels</strong>{' '}
                qui sont passionnés par l'enseignement de la langue allemande.
                <br />
                <br />
                Nous croyons en la puissance de l'éducation pour transformer des
                vies.
              </>
            }
          />

          <MainSectionBody
            id="aboutTeamBody"
            classList="container container-fluid"
          >
            <Team />
          </MainSectionBody>
        </MainSection>

        {/*  -- 
      -----------------------------------------
      OUR PARTNER SECTION
      -----------------------------------------
      -- */}

        <MainSection id="aboutPartners">
          <MainSectionHeader
            id="aboutPartnersHeader"
            title="Nos partenaires !!!"
            description={
              <>
                <strong>
                  Nous travaillons en collaboration avec des partenaires
                  internationaux pour vous offrir le meilleur des services.
                </strong>{' '}
                <br />
                Ces partenaires offrent de l'assistance, du financement, des
                bourses et des opportunités de voyages ! <br />
                <br />{' '}
                <strong>Voici ci-dessous la liste de nos partenaires.</strong>
              </>
            }
          ></MainSectionHeader>

          <MainSectionBody
            id="aboutPartnersBody"
            containerClassList="container container-fluid"
          >
            <div className="card--grid">
              <div className="simple-card partner--card">
                <img
                  src={MbSIKLogo}
                  alt="codes-in"
                  className="partner-card--img"
                />
                <p className="simple-card--text">Mb Sik</p>
              </div>
              <div className="simple-card partner--card">
                <img
                  src={DPLogo}
                  alt="codes-in"
                  className="partner-card--img"
                />
                <p className="simple-card--text">DP - Digital Provider</p>
              </div>
            </div>
          </MainSectionBody>
        </MainSection>

        {/*  -- 
      -----------------------------------------
      GUIDE SECTION
      -----------------------------------------
      -- */}

        <MainSection id="aboutAddress">
          <MainSectionHeader
            id="aboutAddressHeader"
            title="Où nous trouver !!!"
            description={<>Nos différentes adresses et contacts</>}
          />
          <MainSectionBody
            id="aboutAddressBody"
            containerClassList="container container-fluid"
          >
            <div className="about--addresses">
              <article className="address">
                <div className="addresses__title">
                  <h3 className="item--title">
                    SprachFluss Zentrum Douala (siège)
                  </h3>
                </div>
                <div className="addresses__body">
                  <div className="address__item">
                    <span className="address__item--title random-bg">
                      Adresse :
                    </span>{' '}
                    <span className="address__item--text">
                      Carrefour Elf, immeuble Beteumen, 3e Niveau
                    </span>
                  </div>
                  <div className="address__item">
                    <span className="address__item--title random-bg">
                      Contacts :
                    </span>{' '}
                    <span className="address__item--text">
                      +237 6 77 81 76 49 | +237 6 55 13 77 28 | +237 6 77 22 34
                      43
                    </span>
                  </div>
                  <div className="address__item">
                    <span className="address__item--title random-bg">
                      Email(s) :
                    </span>{' '}
                    <span className="address__item--text">
                      dla@sprachfluss-zentrum.com
                    </span>
                  </div>
                </div>
              </article>

              <span className="divider" id="divider-1"></span>

              <article className="card__item address">
                <div className="card__item--content addresses__title">
                  <h3 className="item--title">SprachFluss Zentrum Yaoundé</h3>
                </div>
                <div className=" addresses__body">
                  <div className="address__item">
                    <span className="address__item--title random-bg">
                      Adresse :
                    </span>{' '}
                    <span className="address__item--text">
                      Quartier Essos, Avenue Germaine, immeuble LA GRACE DE
                      DIEU, 3e Niveau
                    </span>
                  </div>
                  <div className="address__item">
                    <span className="address__item--title random-bg">
                      Contacts :
                    </span>{' '}
                    <span className="address__item--text">
                      +237 6 94 41 36 24 | +237 6 53 35 23 15
                    </span>
                  </div>
                  <div className="address__item">
                    <span className="address__item--title random-bg">
                      Email(s) :
                    </span>{' '}
                    <span className="address__item--text">
                      yde@sprachfluss-zentrum.com
                    </span>
                  </div>
                </div>
              </article>
            </div>
          </MainSectionBody>
        </MainSection>

        {/*  -- 
      -----------------------------------------
      CHILDREN SECTION
      -----------------------------------------
      -- */}
      </main>
    </>
  )
}

export default AboutUs
