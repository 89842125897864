import React from 'react'
import {
  computerCourses,
  coursesInformations,
  englishCourses,
  germanCourses,
} from '../../data/courses/coursesData'
import SimpleCard, { MainCard } from '../Cards'
import ComingSoon from '../ComingSoon'

export function CoursesAbout() {
  return (
    <>
      {coursesInformations.length ? (
        coursesInformations.map((info) => {
          const { description, title, id, link } = info
          return (
            <div
              key={`ac${id}`}
              className="card__container my-2 mb-4 col-md-6 col-lg-4 col-12"
            >
              <MainCard title={title} text={description} link={link} />
            </div>
          )
        })
      ) : (
        <ComingSoon />
      )}
    </>
  )
}

export function GermanCoursesCards() {
  return (
    <>
      {germanCourses.length ? (
        germanCourses.map((course) => {
          return (
            <div
              key={`ac${course.id}-${course.path}`}
              className="card__container my-2 mb-4 col-md-6 col-12"
            >
              <SimpleCard
                title={course.title}
                list={course.list}
                linkId={`${course.path}`}
              />
            </div>
          )
        })
      ) : (
        <ComingSoon />
      )}
    </>
  )
}

export function EnglishCoursesCards() {
  return (
    <>
      {englishCourses.length ? (
        englishCourses.map((course) => {
          return (
            <div
              key={`ac${course.id}`}
              className="card__container my-2 mb-4 col-md-6 col-12"
            >
              <SimpleCard
                title={course.title}
                list={course.list}
                linkId={course.id}
              />
            </div>
          )
        })
      ) : (
        <ComingSoon />
      )}
    </>
  )
}

export function ComputerCoursesCards() {
  return (
    <>
      {computerCourses.length ? (
        computerCourses.map((course) => {
          return (
            <div
              key={`ac${course.id}`}
              className="card__container my-2 col-md-6 col-12"
            >
              <SimpleCard
                title={course.title}
                list={course.list}
                linkId={course.id}
              />
            </div>
          )
        })
      ) : (
        <ComingSoon />
      )}
    </>
  )
}
