import React from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'

const InputsField = ({
  name,
  type,
  register,
  style = {},
  className = undefined,
  as = undefined,
  children,
}) => {
  return (
    <FloatingLabel controlId={name} className={className} label={children}>
      <Form.Control
        type={type}
        className="border-opacity-10"
        as={as}
        placeholder={children}
        style={style}
        {...register}
      />
    </FloatingLabel>
  )
}

export default InputsField
