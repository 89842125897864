const ratio = 0.6

// eslint-disable-next-line no-unused-vars
let observer = null

/**
 *
 * @param {HTMLElement} intersecting the html element in intersection with de screen
 */
const setInterscecting = function (intersecting) {
  const intersectingId = intersecting.getAttribute('id')
  const anchor = document.querySelector(`a[href="#${intersectingId}"]`)

  if (anchor === null) return

  anchor.parentElement.parentElement
    .querySelectorAll('.intersecting')
    .forEach((intersection) => intersection.classList.remove('intersecting'))
  anchor.classList.add('intersecting')
}

/**
 *
 * @param {IntersectionObserverEntry[]} entries
 * @param {IntersectionObserver} observer
 */
const callback = function (entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      setInterscecting(entry.target)
    }
  })
}

/**
 *
 * @param {callback} callback
 * @param {number} delay
 * @returns {Function}
 */
export const debounce = function (callback, delay) {
  let timer
  return function () {
    let args = arguments
    let context = this
    clearTimeout(timer)
    timer = setTimeout(function () {
      callback.apply(context, args)
    }, delay)
  }
}

/**
 *
 * @param {NodeListOf.<Element>} spiesElt content all elements to spy
 */
export const scrollSpy = function (spiesElt) {
  const screenY = Math.round(window.innerHeight * ratio)
  const observer = new IntersectionObserver(callback, {
    rootMargin: `-${window.innerHeight - screenY - 1}px 0px -${screenY}px 0px`,
  })
  if (observer !== null) {
    spiesElt.forEach((spy) => observer.unobserve(spy))
  }
  spiesElt.forEach((spy) => observer.observe(spy))
}

// eslint-disable-next-line no-unused-vars
const spies = document.querySelectorAll('[data-spy]')

// if (spies.length) {
//   scrollSpy(spies)

//   let screenH = window.innerHeight
//   window.addEventListener(
//     'resize',
//     debounce(function () {
//       if (screenH !== window.innerHeight) {
//         // console.log('test')
//         scrollSpy(spies)
//         screenH = window.innerHeight
//       }
//     }, 500)
//   )
// }
