import * as React from 'react'
const SvgXmark = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="xmark"
    className="svg-inline--fa fa-regular fa-xmark fa-2x"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      fill="currentColor"
      d="M49.24 45.61a2.57 2.57 0 0 1-3.63 3.64L25 28.64 4.39 49.25a2.57 2.57 0 0 1-3.64-3.64L21.36 25 .75 4.39A2.57 2.57 0 0 1 4.39.75L25 21.36 45.61.75a2.57 2.57 0 1 1 3.63 3.64L28.63 25Z"
      data-name="xmark"
    />
  </svg>
)
export default SvgXmark
