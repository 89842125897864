import cheNeba from '../../assets/img/CHE-NEBA-Marie-Noelle-2.jpeg'
import fokam from '../../assets/img/FOKAM-FOTSO-Fresnel-Maxim-2.jpeg'
import jordan from '../../assets/img/Jordan-Brice-Naoussi-Tchinda-2.jpeg'
import mebou from '../../assets/img/Mebou-Fodouop-Théophile-2.jpeg'
import nicaise from '../../assets/img/Nicaise-KENMOE-2.jpeg'
import youndjeu from '../../assets/img/YOUNJEU-YOUTABAT-MICHELINE-2.jpeg'

export const teamData = [
  {
    id: 1,
    gender: 'F',
    surename: 'Che Neba',
    firstname: 'Marie-Noelle',
    role: 'Fondatrice de PrachFluss Zentrum',
    picture: cheNeba,
    skills: ['Leadership', 'Éducation', 'Développement personnel'],
    active: true,
    disabled: false,
    experience:
      "Plus de 20 ans d'expérience dans l'enseignement et la gestion éducative.",
    education: "PLEG (Professeur des Lycées d'enseignement général)",
    achievements: "Fondatrice d'un programme éducatif innovant",
    preferences: 'Enseigner aux étudiants motivés et passionnés',
    motivations:
      "Favoriser l'apprentissage interculturel et l'ouverture d'esprit",
    goals: "Étendre l'impact éducatif du centre SprachFluss Zentrum",
    qualification:
      "Enseignante PASCH (Écoles, partenaires de l'avenir de l'Institut Goethe)",
    email: 'chenebam@yahoo.de',
    phone: '+237 6 77817649',
    address: 'Logbaba 2 Potos, Route de Ndogpassi, Douala, Cameroun',
    description:
      "Je suis une éducatrice passionnée et une entrepreneuse sociale, ayant une riche expérience dans le domaine de l'enseignement de l'allemand !",
    biography:
      "Marie-Noelle Che Neba est une éducatrice passionnée et une entrepreneuse sociale. Ayant une riche expérience dans le domaine de l'enseignement de l'allemand en tant que seconde langue, elle a fondé SprachFluss Zentrum dans le but de faciliter l'accès à l'Allemagne et de promouvoir les opportunités éducatives et professionnelles pour les Camerounais. Elle est également enseignante PASCH, travaillant en étroite collaboration avec les écoles et les partenaires de l'Institut Goethe pour encourager l'apprentissage de la langue allemande et la compréhension interculturelle. En dehors de son engagement professionnel, Marie-Noelle est une défenseure des droits de l'homme et une militante pour l'éducation inclusive.",
    socialLinks: [
      { platform: 'Facebook', url: 'https://facebook.com/marie-noelle' },
      { platform: 'Twitter', url: 'https://twitter.com/marie-noelle' },
    ],
  },
  {
    id: 2,
    gender: 'M',
    surename: 'Mebou',
    firstname: 'Théophile',
    role: 'Directeur adjoint du centre SFZ',
    picture: mebou,
    skills: ['Gestion', 'Langues étrangères', 'Leadership'],
    active: true,
    disabled: false,
    experience:
      "Titulaire d'une licence en germanique, participation au cours pour jeunes en Allemagne.",
    education: 'Détient plusieurs certificats de langues du Goethe Institut',
    achievements:
      "Participation au projet 'Sprachfluss' organisé par le Docteur Edda Holl",
    preferences: 'Travailler avec des équipes dynamiques et multiculturelles',
    motivations:
      "Promouvoir l'éducation multilingue et l'échange interculturel",
    goals: "Contribuer au développement et à l'expansion du centre SFZ",
    qualification: "Titulaire d'une licence en langues germaniques",
    email: 'mebou.theophile@example.com',
    phone: '+237 6 76543210',
    address: 'Rue des Langues, Quartier Bonamoussadi, Douala, Cameroun',
    description:
      "Je suis un professionnel de l'éducation passionné par l'apprentissage des langues étrangères.",
    biography:
      "Théophile Mebou est le directeur adjoint du centre SFZ. Titulaire d'une licence en langues germaniques, il possède une vaste expérience dans le domaine de l'enseignement des langues étrangères. Il a participé à des cours pour jeunes en Allemagne et détient plusieurs certificats de langues du Goethe Institut. Théophile a également collaboré au projet 'Sprachfluss' organisé par le Docteur Edda Holl, où il a contribué à la promotion de l'apprentissage des langues et de la culture allemande. En tant que directeur adjoint, il s'engage à travailler avec des équipes dynamiques et multiculturelles pour promouvoir l'éducation multilingue et l'échange interculturel au sein du centre SFZ.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/theophile-mebou' },
      { platform: 'Twitter', url: 'https://twitter.com/theophilemebou' },
    ],
  },
  {
    id: 3,
    gender: 'F',
    surename: 'Youndjeu Youtaba',
    firstname: 'Micheline',
    role: 'Représentante extérieure de  Zentrum',
    picture: youndjeu,
    skills: ['Enseignement', 'Encadrement', 'Développement personnel'],
    active: true,
    disabled: false,
    experience:
      'Licence en langues germaniques, enseignement de la langue allemande, encadrement et développement des apprenants en Allemagne',
    education: 'Licence en langues germaniques',
    achievements: 'Lancement réussi de SprachFluss Zentrum',
    preferences: 'Travailler avec des apprenants dynamiques et ambitieux',
    motivations: "Faciliter l'intégration des apprenants en Allemagne",
    goals: "Promouvoir l'apprentissage interculturel et l'échange éducatif",
    qualification: 'Représentante extérieure de SprachFluss Zentrum',
    email: 'micheline.youtaba@example.com',
    phone: '+237 6 71234567',
    address: 'Actuellement en Allemagne',
    description:
      "Je suis passionnée par l'enseignement et le développement personnel des apprenants.",
    biography:
      "Micheline Youndjeu Youtaba est l'une des représentantes extérieures de SprachFluss Zentrum. Actuellement basée en Allemagne, elle est titulaire d'une licence en langues germaniques et possède une solide expérience dans l'enseignement de la langue allemande ainsi que dans l'encadrement et le développement des apprenants. Dynamique et ambitieuse, Micheline a grandement contribué au lancement réussi de SprachFluss Zentrum. Passionnée par l'éducation et l'apprentissage interculturel, elle s'engage à faciliter l'intégration des apprenants en Allemagne et à promouvoir l'échange éducatif entre les deux pays.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/micheline-youtaba' },
      { platform: 'Twitter', url: 'https://twitter.com/michelineyoutaba' },
    ],
  },
  {
    id: 4,
    gender: 'M',
    surename: 'Naoussi Tchinda',
    firstname: 'Jordan Brice',
    role: 'Représentant extérieur de SprachFluss',
    picture: jordan,
    skills: ['Enseignement', 'Encadrement', 'Développement personnel'],
    active: true,
    disabled: false,
    experience:
      'Licence en langues germaniques, enseignement de la langue allemande, encadrement et développement des apprenants en Allemagne',
    education: 'Licence en langues germaniques',
    achievements: 'Développement de programmes éducatifs innovants',
    preferences: 'Travailler avec des apprenants motivés et engagés',
    motivations: "Faciliter l'intégration des apprenants en Allemagne",
    goals: "Contribuer à l'essor de SprachFluss",
    qualification: 'Représentant extérieur de SprachFluss',
    email: 'jordan.brice@example.com',
    phone: '+237 6 71234567',
    address: 'Actuellement en Allemagne',
    description:
      "Je suis passionné par l'enseignement et le développement personnel des apprenants.",
    biography:
      "Jordan Brice Naoussi Tchinda est l'un des représentants extérieurs de SprachFluss. Actuellement basé en Allemagne, il est titulaire d'une licence en langues germaniques et possède une solide expérience dans l'enseignement de la langue allemande ainsi que dans l'encadrement et le développement des apprenants. Passionné par l'éducation, Jordan Brice s'engage à faciliter l'intégration des apprenants en Allemagne et à contribuer à l'essor continu de SprachFluss. En tant que représentant extérieur, il aspire à promouvoir les activités du centre et à établir des partenariats stratégiques avec des institutions et des organisations en Allemagne.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/jordan-brice' },
      { platform: 'Twitter', url: 'https://twitter.com/jordanbrice' },
    ],
  },
  {
    id: 5,
    gender: 'M',
    surename: 'Kenmoe',
    firstname: 'Nicaise',
    role: 'Partenaire et gestionnaire des finances de SprachFluss Zentrum',
    picture: nicaise,
    skills: ['Gestion financière', 'Entrepreneuriat', 'Marketing'],
    active: true,
    disabled: false,
    experience: "Expérience dans la gestion financière et l'entrepreneuriat",
    education: 'Diplôme en administration des affaires',
    achievements: 'Création réussie de son entreprise',
    preferences: 'Travailler sur des projets innovants et stimulants',
    motivations:
      'Contribuer à la croissance et au développement de SprachFluss Zentrum',
    goals: "Augmenter la visibilité et l'impact du centre",
    qualification:
      'Partenaire et gestionnaire des finances de SprachFluss Zentrum',
    email: 'nicaise.kenmoe@example.com',
    phone: '+237 6 71234567',
    address: 'Douala, Cameroun',
    description:
      'Je suis un entrepreneur passionné par la création de nouvelles opportunités et la croissance des entreprises.',
    biography:
      "Nicaise Kenmoe est un entrepreneur passionné par la création de nouvelles opportunités et la croissance des entreprises. Titulaire d'un diplôme en administration des affaires, il a créé avec succès son entreprise en partenariat avec un collaborateur. Actuellement, il est partenaire et gestionnaire des finances de SprachFluss Zentrum, contribuant à la croissance et au développement du centre. Nicaise est motivé par le désir d'augmenter la visibilité et l'impact du centre, et il est engagé à travailler sur des projets innovants et stimulants pour propulser SprachFluss Zentrum vers l'avant.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/nicaise-kenmoe' },
      { platform: 'Twitter', url: 'https://twitter.com/nicaisekenmoe' },
    ],
  },
  {
    id: 6,
    gender: 'M',
    surename: 'FOKAM FOTSO',
    firstname: 'Fresnel Maxim',
    role: 'Enseignant au centre SprachFluss Zentrum',
    picture: fokam,
    skills: ['Communication', 'Pédagogie', 'Enseignement'],
    active: true,
    disabled: false,
    experience: "Expérience dans l'enseignement de la langue allemande",
    education: 'Licence en langues germaniques',
    achievements: 'Reconnu pour son inspiration et son engagement',
    preferences: 'Travailler avec des apprenants motivés et passionnés',
    motivations:
      "Favoriser l'apprentissage et le développement personnel de ses élèves",
    goals: "Contribuer à la réussite et à l'épanouissement de ses élèves",
    qualification: 'Enseignant au centre SprachFluss Zentrum',
    email: 'fresnel.fokam@example.com',
    phone: '+237 6 71234567',
    address: 'Douala, Cameroun',
    description:
      "Je suis passionné par l'enseignement et le développement personnel de mes élèves.",
    biography:
      "Fresnel Maxim FOKAM FOTSO est un enseignant dévoué au centre SprachFluss Zentrum. Titulaire d'une licence en langues germaniques, il possède une solide expérience dans l'enseignement de la langue allemande. Reconnu pour son inspiration et son engagement, Fresnel est passionné par l'enseignement et le développement personnel de ses élèves. Il est motivé par le désir de favoriser l'apprentissage et la croissance de ses élèves, et il est déterminé à contribuer à leur réussite et à leur épanouissement.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/fresnel-fokam-fotso' },
      { platform: 'Twitter', url: 'https://twitter.com/fresnelfokam' },
    ],
  },
  {
    id: 7,
    gender: 'M',
    surename: 'Kemgang',
    firstname: 'Karl Gabin',
    role: 'Informaticien de SprachFluss Zentrum',
    picture: '',
    skills: [
      'Développement web',
      'Ingénierie des systèmes et réseaux',
      'Freelancing',
    ],
    active: true,
    disabled: false,
    experience:
      "Expérience dans le développement web et l'ingénierie des systèmes",
    education: 'Licence en ingénierie des systèmes et réseaux',
    achievements: 'Développement du site web de SprachFluss Zentrum',
    preferences: 'Travailler sur des projets innovants et stimulants',
    motivations:
      'Contribuer au succès et à la croissance de SprachFluss Zentrum',
    goals: 'Fournir des solutions technologiques efficaces pour le centre',
    qualification: 'Informaticien de SprachFluss Zentrum',
    email: 'karl.kemgang@example.com',
    phone: '+223 6 71234567',
    address: 'Hippodrome I, Bamako, Mali',
    description:
      'Je suis passionné par la programmation et le développement de solutions innovantes.',
    biography:
      "Karl Gabin Kemgang est un informaticien dédié au centre SprachFluss Zentrum. Titulaire d'une licence en ingénierie des systèmes et réseaux, il possède une solide expérience dans le développement web et l'ingénierie des systèmes. Karl a contribué au succès de SprachFluss Zentrum en développant le site web du centre. Passionné par la programmation et les solutions innovantes, il est motivé par le désir de fournir des solutions technologiques efficaces pour soutenir la croissance et le succès du centre.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/karlgabinkemgang' },
      { platform: 'GitHub', url: 'https://github.com/karlgabinkemgang' },
    ],
  },
  {
    id: 8,
    gender: 'F',
    surename: 'Dzeme',
    firstname: 'Beatrice',
    role: 'Conseillère pédagogique de SprachFluss Zentrum',
    picture: '',
    skills: [
      'Conseil pédagogique',
      'Développement professionnel',
      'Gestion de classe',
    ],
    active: true,
    disabled: false,
    experience: "Plusieurs années d'expérience en conseil pédagogique",
    education: 'Master en Éducation',
    achievements: 'Amélioration des performances académiques des étudiants',
    preferences: 'Travailler avec des enseignants motivés et dévoués',
    motivations: "Contribuer à l'excellence éducative de SprachFluss Zentrum",
    goals: 'Fournir des conseils pédagogiques efficaces pour le centre',
    qualification: 'Conseillère pédagogique de SprachFluss Zentrum',
    email: 'beatrice@example.com',
    phone: '+237 6 71234567',
    address: 'Douala, Cameroun',
    description:
      "Je suis passionnée par l'éducation et le développement professionnel des enseignants.",
    biography:
      "Beatrice est une conseillère pédagogique dédiée à SprachFluss Zentrum. Titulaire d'un master en éducation, elle possède plusieurs années d'expérience en conseil pédagogique. Beatrice est passionnée par l'éducation et le développement professionnel des enseignants. Elle est motivée par le désir de contribuer à l'excellence éducative de SprachFluss Zentrum en fournissant des conseils pédagogiques efficaces et en travaillant avec des enseignants motivés et dévoués.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/beatrice' },
      { platform: 'Twitter', url: 'https://twitter.com/beatrice' },
    ],
  },
  {
    id: 9,
    gender: 'F',
    surename: 'Rolande',
    firstname: '',
    role: 'Responsable des partenariats de SprachFluss Zentrum',
    picture: '',
    skills: [
      'Relations internationales',
      'Gestion de partenariats',
      'Développement éducatif',
    ],
    active: true,
    disabled: true,
    experience: 'Expérience dans la gestion de partenariats éducatifs',
    education: 'Master en Relations internationales',
    achievements:
      'Élargissement des opportunités éducatives pour les apprenants',
    preferences:
      'Travailler avec des organisations éducatives et des institutions internationales',
    motivations:
      "Contribuer à l'expansion des partenariats de SprachFluss Zentrum",
    goals: 'Établir des collaborations stratégiques pour le centre',
    qualification: 'Responsable des partenariats de SprachFluss Zentrum',
    email: 'rolande@example.com',
    phone: '+237 6 71234567',
    address: 'Yaoundé, Cameroun',
    description:
      'Je suis passionnée par les relations internationales et le développement éducatif.',
    biography:
      "Rolande est responsable des partenariats de SprachFluss Zentrum. Titulaire d'un master en relations internationales, elle possède une solide expérience dans la gestion de partenariats éducatifs. Rolande est passionnée par les relations internationales et le développement éducatif. Elle est motivée par le désir d'élargir les opportunités éducatives pour les apprenants de SprachFluss Zentrum en établissant des collaborations stratégiques avec des organisations éducatives et des institutions internationales.",
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/rolande' },
      { platform: 'Facebook', url: 'https://facebook.com/rolande' },
    ],
  },
]
