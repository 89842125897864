import { useRouteError } from 'react-router-dom'
import ErrorPicture from '../../assets/icons/404blue-svg.svg'

function ErrorPage() {
  const error = useRouteError()
  console.log(error)
  return (
    <div className="relative">
      <div className="container container-fluid">
        <div className="err--div row">
          <div className="row row-fitheight">
            <div className="err--title row row-fitheight">
              <span> SFZ - Oops !!</span>
            </div>
            <div className="err--desc">
              <div className="row row-fitheight">
                <i>
                  {error.status === 404
                    ? "Cette page n'existe pas ou du moins plus !!!"
                    : "Une erreur s'est produite !!!"}
                </i>
              </div>
              <div className="row row-fitheight">
                <i>{error.statusText || error.message}</i>
              </div>
            </div>
          </div>
        </div>
        <div className="error--img">
          {error.status === 404 ? (
            <img src={ErrorPicture} alt="Not found" />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
