import React from 'react'
import PropTypes, { element, string } from 'prop-types'

const AboutUsCard = ({ id, title, image, description }) => {
  return (
    <article className="card-item" id={id}>
      <div className="card-item__title">
        <h3 className="card-item__title--text h3">{title}</h3>
      </div>
      <div className="card-item__img about__img">
        <img
          src={image}
          className="about__img--img img-fluid m-auto"
          alt=""
          id="aboutLogo"
        />
      </div>
      <p className="card_-itemdecription">{description}</p>
      <span className="card-item--bar random-bg" id="bar1"></span>
    </article>
  )
}

AboutUsCard.propTypes = {
  id: string.isRequired,
  image: string.isRequired,
  title: PropTypes.oneOfType([string, element]).isRequired,
  description: PropTypes.oneOfType([string, element]).isRequired,
}

export default AboutUsCard
