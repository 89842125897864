import * as React from 'react'
const SvgChampagneGlasses = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="champagne-glasses"
    className="svg-inline--fa fa-champagne-glasses fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 80.06"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.12 66.94c.58-1.47 1.15-2.89 1.72-4.3 1.53-3.8 3.05-7.61 4.61-11.39A.86.86 0 0 0 18.1 50a18.62 18.62 0 0 1-6.34-7.69c-2.09-4.67-2.4-9.46-.22-14.15 1.64-3.52 3.63-6.88 5.51-10.28C19.77 13 22.53 8.1 25.27 3.21a5.53 5.53 0 0 1 3.3-3 6.11 6.11 0 0 1 3.89.29Q40.82 3.76 49.17 7a2 2 0 0 0 1.58 0C56.33 4.85 61.93 2.67 67.52.48c2.29-.89 4.47-.54 5.86 1a10.06 10.06 0 0 1 1.14 1.72q6.41 11.29 12.8 22.6a17.55 17.55 0 0 1 0 18.22 19.86 19.86 0 0 1-5.46 6c-.36.26-.61.46-.38 1 2.08 5.15 4.12 10.3 6.18 15.46 0 .12.11.23.21.42l2.06-.81c1.67-.67 3.34-1.35 5-2a3.66 3.66 0 0 1 4.65 1.94 3.3 3.3 0 0 1-1.26 4.49A25.91 25.91 0 0 1 95.18 72q-9.36 3.87-18.73 7.7a3.53 3.53 0 0 1-3.93-.59 3.69 3.69 0 0 1-1.19-3.95A2.91 2.91 0 0 1 73 73.24c2.24-1 4.52-1.95 6.78-2.92l.92-.4c-.15-.4-.27-.74-.41-1.08-2-4.91-3.92-9.82-5.84-14.75-.27-.67-.6-.77-1.25-.69A19.25 19.25 0 0 1 58.94 50a17.86 17.86 0 0 1-6.83-10.33c-.76-3-1.5-6-2.3-9.15l-2.17 8.72a18.51 18.51 0 0 1-6.33 10.29 18.06 18.06 0 0 1-10.21 4 47.22 47.22 0 0 1-4.9-.12c-.4 0-.67 0-.86.45q-3.12 7.91-6.27 15.79a1.19 1.19 0 0 0-.06.31c.24.11.49.24.75.35 2 .85 4.12 1.67 6.16 2.54a3.6 3.6 0 0 1 2.36 4.59 3.44 3.44 0 0 1-4.68 2.36q-10.52-4.31-21-8.63C.46 70.26-.35 68.74.13 66.68a3.69 3.69 0 0 1 4.77-2.59c2.15.82 4.28 1.71 6.42 2.56.24.1.49.18.8.29Zm10.67-44.52c-1.58 3.06-3.24 6-4.63 9.09a8.39 8.39 0 0 0-.14 7.1 12.22 12.22 0 0 0 8.64 7.21c5.44 1.32 11.06-.19 13.31-6.53a82.11 82.11 0 0 0 2.1-8.13c0-.15-.23-.49-.44-.58-.85-.42-1.73-.78-2.61-1.15Zm54.26.2L71.54 25l-13.1 5.67c-.33.14-.72.2-.56.76.76 2.69 1.34 5.45 2.26 8.09 1.62 4.64 4.72 6.51 9.54 6.71a12.47 12.47 0 0 0 9.68-3.83c2.72-2.74 4.19-6 2.78-9.87A47.69 47.69 0 0 0 79.5 27c-.76-1.45-1.61-2.88-2.45-4.38ZM53.56 14l2.36 9.64 17.43-7.54-4.56-8Zm-26.8 2.1L44 23.65c.85-3.26 1.68-6.44 2.52-9.64l-15.2-5.93Z"
    />
  </svg>
)
export default SvgChampagneGlasses
