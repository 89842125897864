import { Carousel } from 'react-bootstrap'
import { carouselItemsData } from '../../data/carousels/carouselItemsData'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import appColors from '../../utils/style/appColors'

const ContactLink = styled(Link)`
  background-color: ${appColors.primary};
  border-color: ${appColors.primary};
`

const KnowMoreLink = styled(Link)`
  background-color: ${appColors.orange};
  border-color: ${appColors.orange};
`

function HomeCarousel() {
  return (
    <>
      <Carousel controls={false} defaultActiveIndex={2} fade>
        {carouselItemsData.map(({ id, itemClass, image, body, caption }) => (
          <Carousel.Item key={id} id={id} className={`${itemClass}`}>
            <div className="carousel-item-wrapper container-md carousel-container">
              <div className={`${image.parentClass} icover`} id={image.id}>
                <img
                  src={image.path}
                  alt={image.alt}
                  className={`${image.classList} icover--image`}
                />
                {image.blendMode && <div className="img__blendmode"></div>}
              </div>
              <div id={body.id} className={`${body.classList}`}>
                <h1
                  id={body.title.id}
                  className={`${body.title.classList}`}
                  dangerouslySetInnerHTML={{ __html: body.title.text }}
                />
              </div>
              <div id={caption.id} className={`${caption.classList}`}>
                <h3
                  id={caption.title.id}
                  className={`${caption.title.classList}`}
                  dangerouslySetInnerHTML={{ __html: caption.title.text }}
                />
                <p
                  id={caption.description.id}
                  className={`${caption.description.classList}`}
                  dangerouslySetInnerHTML={{ __html: caption.description.text }}
                />
                <div
                  id={caption.button.id}
                  className={`${caption.button.classList}`}
                >
                  <ContactLink
                    id={caption.button.contact.id}
                    className={`${caption.button.contact.classList} btn btn-primary`}
                    to={caption.button.contact.path}
                    role="button"
                  >
                    {caption.button.contact.text}
                  </ContactLink>
                  <KnowMoreLink
                    id={caption.button.more.id}
                    className={`${caption.button.more.classList} btn btn-secondary`}
                    to={caption.button.more.path}
                    role="button"
                  >
                    {caption.button.more.text} &raquo;
                  </KnowMoreLink>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}

export default HomeCarousel
