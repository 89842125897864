import { Grid, Typography, useTheme } from '@mui/material'
import { Container } from 'react-bootstrap'
import { getDefaultAvatar } from '../../utils/functions'
import { useStyles } from '../../utils/hooks/useStyle'

const MemberDetails = ({ member }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    member && (
      <Container>
        <Grid
          container
          spacing={4}
          marginBottom={'2em'}
          className={classes.container}
        >
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <img
              src={member.picture || getDefaultAvatar(member.gender)}
              alt={`${member.firstname} ${member.surename}`}
              className={classes.avatar}
            />
            <Typography
              variant="h4"
              gutterBottom={true}
              className={classes.name}
            >
              {`${member.firstname} ${member.surename.toUpperCase()}`}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.role}
            >
              {member.role}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Expérience:</strong> {member.experience}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Éducation:</strong> {member.education}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Réalisations:</strong> {member.achievements}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Compétences:</strong> {member.skills.join(', ')}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.status}
            >
              <strong>Statut:</strong> {member.active ? 'Actif' : 'Inactif'}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Biographie :</strong> {member.biography}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Objectifs:</strong> {member.goals}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Qualification:</strong> {member.qualification}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Motivations:</strong> {member.motivations}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Préférences:</strong> {member.preferences}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Email:</strong> {member.email}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Téléphone:</strong> {member.phone}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.text}
            >
              <strong>Adresse:</strong> {member.address}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    )
  )
}

export default MemberDetails
