import React from 'react'
import PropTypes from 'prop-types'

function UlComponentOne({ id, classList, children }) {
  return (
    <>
      {/*
        -----------------------------------------
        MAIN SECTION
        -----------------------------------------
      */}
      <div className={`ul${classList ? ' ' + classList : ''}`} id={id}>
        {children}
      </div>
    </>
  )
}

UlComponentOne.propTypes = {
  id: PropTypes.string.isRequired,
  classList: PropTypes.string,
}

export default UlComponentOne
