import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

// import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './styles/css/styles.css'
import reportWebVitals from './reportWebVitals'
import router from './routes/root'
import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
