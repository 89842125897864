import React from 'react'
import PropTypes, { element, string } from 'prop-types'

function SectionItemOne({ id, title, description, children }) {
  return (
    <>
      {/*
        -----------------------------------------
        SECTION ITEM ONE
        -----------------------------------------
      */}
      <div className="section-item__one" id={id} data-spy>
        <div className="section-item__one-header">
          <h3 className="section-item__one-header__title h3">{title}</h3>
          {description && (
            <p className="section-item__one-header__description text-center text-lg-start">
              {description}
            </p>
          )}
        </div>
        {children && <div className="section-item__one-body">{children}</div>}
      </div>
    </>
  )
}

SectionItemOne.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([string, element]),
}

export default SectionItemOne
