import * as React from 'react'
const SvgPlane = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="plane"
    className="svg-inline--fa fa-plane fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 89.02"
    {...props}
  >
    <path
      fill="currentColor"
      d="M32.8 58.4c-2.47 0-4.86.1-7.23 0a3.22 3.22 0 0 0-3.22 1.6c-1.58 2.34-3.36 4.54-5 6.83a6.13 6.13 0 0 1-5.35 2.7H5.38a5.06 5.06 0 0 1-5.18-4 6.43 6.43 0 0 1 .1-2.86c1.57-5.93 3.26-11.77 4.9-17.62a2 2 0 0 0 0-1.05c-1.64-5.85-3.31-11.69-5-17.55a5.23 5.23 0 0 1 3.54-6.7 7 7 0 0 1 1.77-.21h6.55a6 6 0 0 1 5.15 2.61c2 2.68 4 5.34 5.95 8a1.07 1.07 0 0 0 1 .5h8.59c-.05-.24-.08-.43-.13-.61q-3.19-11.27-6.44-22.52A5.56 5.56 0 0 1 31.56 0H41a8.45 8.45 0 0 1 7.63 4.53Q55.94 17.31 63.21 30a1.08 1.08 0 0 0 1.09.62h15.36a24.77 24.77 0 0 1 16.39 6.06 10.41 10.41 0 0 1 3.87 6.72 9.21 9.21 0 0 1-2.29 7.35 22.48 22.48 0 0 1-10 6.36 24 24 0 0 1-8.45 1.3h-15a1 1 0 0 0-1 .56q-7.17 12.55-14.36 25.1a8.43 8.43 0 0 1-4.45 4.23A11.87 11.87 0 0 1 41 89c-3.1.1-6.22.05-9.33 0a5.58 5.58 0 0 1-5.5-3.79 6 6 0 0 1 .07-3.76q3.2-11.23 6.41-22.45c.05-.16.09-.33.15-.6Zm-1.42 25.07h9.23a3.38 3.38 0 0 0 3.31-1.92c5.12-9 10.3-17.89 15.41-26.86a3.31 3.31 0 0 1 3.21-1.85h17.61a16.33 16.33 0 0 0 5.72-.84 17.21 17.21 0 0 0 7.34-4.55 4 4 0 0 0-.06-6 16.86 16.86 0 0 0-5-3.36 17.58 17.58 0 0 0-8.15-2H62.46a3.27 3.27 0 0 1-3.16-1.82c-5.13-9-10.33-18-15.47-27a3.22 3.22 0 0 0-3.1-1.82h-9.37c.08.3.14.53.21.76q3.75 13 7.51 26a2.78 2.78 0 0 1-2.81 3.82h-14a2.94 2.94 0 0 1-2.53-1.31c-2.27-3.12-4.59-6.2-6.88-9.32a1.13 1.13 0 0 0-1-.51C10 25 8.21 25 6.4 25h-.72c.07.27.11.46.16.65 1.67 6 3.35 12 5 18a3.1 3.1 0 0 1 0 1.6c-1.64 6-3.32 12-5 18-.06.22-.1.45-.16.73h6a1.4 1.4 0 0 0 1.32-.61c2.22-3 4.49-6 6.69-9a3.22 3.22 0 0 1 2.85-1.46h13.57a4.5 4.5 0 0 1 1.37.2 2.71 2.71 0 0 1 1.67 3.59q-2.71 9.3-5.44 18.71c-.71 2.65-1.53 5.29-2.33 8.06Z"
    />
  </svg>
)
export default SvgPlane
