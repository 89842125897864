import React from 'react'
import { ThreeDotsVertical } from 'react-bootstrap-icons'
import CoursesPicture from '../../assets/img/sfz-cours.png'

import Sidebar from '../../components/Sidebar'
import FooterComponent from '../../components/Footers'
import ScrollToTop from '../../components/ScrollToTop'
import { useParams } from 'react-router-dom'
import NavbarSticky from '../../components/NavbarSticky'
import { germanCoursesOffersData } from '../../data/services/servicesData'

export function loader({ params }) {}

function CoursesDetails() {
  const { option } = useParams()

  const allData = germanCoursesOffersData.find(
    (service) => service.id === option
  )

  const handleSidebarShow = () => {
    document.getElementById('mainSidebar').style.left = '00px'
    document.getElementById('contentWrapper').style.left = '300px'
    document.getElementById('sidebarButton').style.display = 'none'
    document.getElementById('wrapperOverlay').style.display = 'block'
    document.body.style.overflow = 'hidden'
  }

  return (
    <>
      <ScrollToTop>
        <Sidebar mobile={true} />
        <main className="main-wrapper" id="contentWrapper">
          <div className="overlay" id="wrapperOverlay"></div>
          <NavbarSticky />
          <header
            id="coursesDetailsHeader"
            className="blendmode-header main-header"
          >
            <div className="blendmode-header__img">
              <img
                src={CoursesPicture}
                alt="En cours"
                className="img-fluid item-image-img"
              />
              <div className="img__blendmode"></div>
            </div>
            <div className="blendmode-header__body">
              <div className="blendmode-header__body--title">
                <h1 className="blendmode-header__body--title-h1 h1 courses--detail__main-title">
                  SFZ - "La référence en cours de langues professionnelles"
                </h1>
              </div>
            </div>
          </header>

          <section className="sections__wrapper py-4" id="coursesSection">
            <div className="sections__container container-lg container-fluid">
              <Sidebar />
              <div
                className="sidebar__button link__btn--secondary"
                id="sidebarButton"
              >
                <ThreeDotsVertical
                  className="sidebar__button--show"
                  onClick={handleSidebarShow}
                />
              </div>

              {/*  -- 
              -----------------------------------------
              COURSE DETAILS SECTION
              -----------------------------------------
              -- */}

              <div className="course-details px-4" id="courseDetails" data-spy>
                {allData ? (
                  <>
                    <h2
                      className="section__title section--h2 h2"
                      dangerouslySetInnerHTML={{
                        __html: allData?.title,
                      }}
                    />
                    <p
                      className="section__description text-center text-lg-start"
                      dangerouslySetInnerHTML={{
                        __html: allData.description,
                      }}
                    />
                    <div className="section__body">
                      <div className="course-item" id="course1" data-spy>
                        <h3 className="course__title section--h3 h3">
                          Cours d'Allemand
                        </h3>
                        <p className="section__body--description text-center text-lg-start">
                          Nos cours d'allemand sont organisés de manière
                          progressive pour répondre aux besoins des apprenants
                          de tous niveaux.
                        </p>
                        <div className="course__body"></div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </section>
          <FooterComponent />
        </main>
      </ScrollToTop>
    </>
  )
}

export default CoursesDetails
