import React from 'react'
import PropTypes, { element, string } from 'prop-types'

function SectionHeaderOne({ id, title, description, classList }) {
  return (
    <>
      {/*
        -----------------------------------------
        SECTION HEADER ONE
        -----------------------------------------
      */}
      <div
        className={`section__header${classList ? ' ' + classList : ''}`}
        id={id}
        data-spy
      >
        <div className="container-lg container-fluid py-2">
          <h2 className="section__header--title px-4 section--h2 h2">
            {title}
          </h2>
          {description && (
            <p className="section__header--description">{description}</p>
          )}
        </div>
      </div>
    </>
  )
}

SectionHeaderOne.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([string, element]).isRequired,
  classList: PropTypes.string,
  description: PropTypes.oneOfType([string, element]),
}

export default SectionHeaderOne
