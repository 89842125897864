import * as React from 'react'
const SvgWheatSlash = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="wheat-slash"
    className="svg-inline--fa fa-wheat-slash fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 79.97"
    {...props}
  >
    <g>
      <path
        fill="currentColor"
        d="M35 23.53c1.08-1.11 2.06-2.17 3.1-3.18a2.54 2.54 0 0 1 3.71-.14 21.5 21.5 0 0 1 7 11.52c.12.57.19 1.15.32 1.9l4.9-4.75c-.71-.78-1.36-1.51-2-2.22a14.44 14.44 0 0 1-.46-19.55c.78-.91 1.59-1.78 2.4-2.65a2.57 2.57 0 0 1 3.82-.17c2.32 2.39 4.56 4.87 5.83 8a19.31 19.31 0 0 1 1 12.56c-.06.25-.1.51-.17.85 1-.19 1.9-.4 2.83-.54A18.72 18.72 0 0 1 83.3 30c.84.76 1.7 1.51 2.53 2.29a2.65 2.65 0 0 1 0 4.1 22.19 22.19 0 0 1-5.73 4.33 14.47 14.47 0 0 1-14.12-.75 45 45 0 0 1-4.7-3.7l-4.58 4.2 4 3.15 37.5 29.35c1.72 1.34 2.24 3 1.54 4.73a3.74 3.74 0 0 1-6 1.29c-2.82-2.19-5.63-4.4-8.44-6.6L40.62 37.42 1.83 7C.13 5.69-.4 4 .3 2.29S3.62-1.1 6.34 1L15 7.78l19.5 15.35Zm-1.55 25.95L37.87 45l8 6.3-5.58 5.61c1 .18 1.85.3 2.69.5a20.67 20.67 0 0 1 9.67 5.5c.48.45.94.92 1.39 1.41a2.59 2.59 0 0 1 .11 3.66 16.79 16.79 0 0 1-8 5.42 14.59 14.59 0 0 1-14-2.88c-1-.83-1.91-1.74-3-2.75a5.32 5.32 0 0 1-.51.69q-4.92 4.95-9.86 9.89A5.4 5.4 0 0 1 13 79.58a5.05 5.05 0 0 1-2.08-7.53 7.37 7.37 0 0 1 .92-1.05q4.83-4.77 9.69-9.51a5.55 5.55 0 0 1 .76-.53c-.79-.9-1.41-1.65-2.07-2.36A15 15 0 0 1 21 37.46c.4-.39.78-.79 1.19-1.17a2.59 2.59 0 0 1 3.81-.11c3.71 3.41 6.5 7.38 7.29 12.49-.01.25.08.49.16.81Z"
      />
      <path
        fill="currentColor"
        d="M90 5.2a13.94 13.94 0 0 1-2.59 9 13.22 13.22 0 0 1-9.78 5.62C76 20 74.39 20 72.78 20s-2.69-.88-2.69-2.48a39.55 39.55 0 0 1 .35-6.54A13.24 13.24 0 0 1 81.07.36a36.86 36.86 0 0 1 6.4-.36A2.35 2.35 0 0 1 90 2.6v2.6Z"
      />
    </g>
  </svg>
)
export default SvgWheatSlash
