import React from 'react'
import { ThreeDotsVertical } from 'react-bootstrap-icons'
import CoursesPicture from '../../assets/img/sfz-cours.png'

import Sidebar from '../../components/Sidebar'
import FooterComponent from '../../components/Footers'
import ScrollToTop from '../../components/ScrollToTop'
import NavbarSticky from '../../components/NavbarSticky'
import { germanCourses } from '../../data/courses/coursesData'
import { MainCard } from '../../components/Cards'
import ComingSoon from '../../components/ComingSoon'
import SectionItemOne from '../../components/Sections/SectionItemOne'
import { Link } from 'react-router-dom'

export function loader({ params }) {}

const studyTripData = germanCourses.find(
  (course) => course.path === 'study-trips'
)

const highlights = studyTripData?.highlights

const sectionItemsData = [
  {
    id: 'st-itemOne',
    title: 'Expérience Pédagogique Enrichissante',
    description: (
      <>
        Nos cours pour voyage d'études sont conçus pour vous offrir une
        expérience d'apprentissage inoubliable. <br /> <br /> Que vous soyez un
        étudiant en quête de connaissances, un professionnel en recherche de
        perfectionnement ou un voyageur désireux d'explorer une nouvelle
        culture, nos cours vous aideront à atteindre vos objectifs tout en
        découvrant les charmes de l'Allemagne.
      </>
    ),
  },
  {
    id: 'st-itemTwo',
    title: "Points Forts de Nos Cours pour Voyage d'Études",
    description: '',
    children: highlights ? (
      highlights.map((detail) => {
        return (
          <div
            key={`trip-${detail.id}`}
            className="card__container my-2 col-md-6 col-12"
          >
            <MainCard title={detail.title} text={detail.description} />
          </div>
        )
      })
    ) : (
      <ComingSoon />
    ),
  },
  {
    id: 'st-itemThree',
    title: 'Niveaux A1 et A2 : Les Fondations de la Langue',
    description: (
      <>
        Nos cours de niveau A1 et A2 sont conçus pour les débutants. Vous
        apprendrez les bases de la langue, y compris les expressions courantes,
        la grammaire élémentaire et la construction de phrases simples. <br />
        <br />
        En utilisant des méthodes interactives et des exercices pratiques, nous
        vous aiderons à{' '}
        <strong>
          acquérir la confiance nécessaire pour communiquer dans des situations
          quotidiennes
        </strong>
        .
      </>
    ),
  },
  {
    id: 'st-itemFour',
    title: 'Niveaux B1 et B2 : La Communication Avancée',
    description: (
      <>
        Les cours de niveau B1 et B2 visent à renforcer vos compétences de
        communication. Vous développerez votre <strong>vocabulaire</strong>,
        votre <strong>compréhension orale et écrite</strong>, ainsi que votre
        capacité à exprimer des idées plus complexes. <br />
        <br />
        <strong>
          Des discussions de groupe, des présentations et des activités
          interactives
        </strong>{' '}
        vous prépareront à interagir en allemand dans des{' '}
        <strong>environnements professionnels et sociaux</strong>.
      </>
    ),
  },
  {
    id: 'st-itemFive',
    title: "Niveaux C1 : L'Excellence Linguistique",
    description: (
      <>
        Pour ceux qui visent l'excellence, notre cours de niveau C1 est conçu
        pour vous aider à maîtriser la langue allemande à un niveau avancé.{' '}
        <br />
        <br />
        Vous
        <strong> perfectionnerez vos compétences linguistiques</strong>,
        développerez une{' '}
        <strong>compréhension approfondie de la culture allemande</strong> et
        serez capable de vous{' '}
        <strong>exprimer avec précision et aisance </strong>
        dans des contextes complexes.
      </>
    ),
  },
]

function StudyTripCourses() {
  const handleSidebarShow = () => {
    document.getElementById('mainSidebar').style.left = '00px'
    document.getElementById('contentWrapper').style.left = '300px'
    document.getElementById('sidebarButton').style.display = 'none'
    document.getElementById('wrapperOverlay').style.display = 'block'
    document.body.style.overflow = 'hidden'
  }

  return (
    <>
      <ScrollToTop>
        <Sidebar mobile={true} />
        <main className="main-wrapper" id="contentWrapper">
          <div className="overlay" id="wrapperOverlay"></div>
          <NavbarSticky />
          <header
            id="StudyTripCoursesHeader"
            className="blendmode-header main-header courses-header"
          >
            <div className="blendmode-header__img">
              <img
                src={CoursesPicture}
                alt="En cours"
                className="img-fluid item-image-img"
              />
              <div className="img__blendmode" />
            </div>
            <div className="blendmode-header__body">
              <div className="blendmode-header__body--title">
                <h1 className="blendmode-header__body--title-h1 h1 courses--detail__main-title">
                  Découvrez le Monde à travers <br /> Nos Cours pour Voyage
                  d'Études en Allemagne
                </h1>
              </div>
            </div>
          </header>

          <section className="sections__wrapper py-4" id="coursesSection">
            <div className="sections__container container-lg container-fluid">
              <Sidebar />
              <div
                className="sidebar__button link__btn--secondary"
                id="sidebarButton"
              >
                <ThreeDotsVertical
                  className="sidebar__button--show"
                  onClick={handleSidebarShow}
                />
              </div>

              {/*  -- 
              -----------------------------------------
              COURSE DETAILS SECTION
              -----------------------------------------
              -- */}

              <div className="course-details px-4" id="courseDetails" data-spy>
                <div className="section-header">
                  <h2 className="section__title section--h2 h2">
                    Cours pour voyage d'études en Allemagne
                  </h2>
                  <p className="section__description text-center text-lg-start">
                    Chez <strong>SFZ</strong>, nous croyons en l'apprentissage
                    qui va au-delà des salles de classe traditionnelles. C'est
                    pourquoi nous sommes ravis de vous présenter nos cours
                    spécialement conçus pour les voyages d'étude en Allemagne.
                    Ces cours offrent une opportunité unique de combiner
                    l'apprentissage linguistique avec une expérience culturelle
                    immersive.
                  </p>
                </div>

                <div className="section__body">
                  {sectionItemsData.map((item) => (
                    <SectionItemOne
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      description={item.description}
                    >
                      {item.children && (
                        <div className="course justify-content-center justify-content-lg-between">
                          {item.children}
                        </div>
                      )}
                    </SectionItemOne>
                  ))}
                </div>

                <div className="section__footer">
                  <div className="section__footer--container container-md container-fluid">
                    <p className="section__footer--text text-highlighted">
                      Nos cours pour voyages d'étude offrent l'équilibre parfait
                      entre l'apprentissage en classe et l'exploration
                      culturelle. Vous apprendrez la langue tout en vivant la
                      culture, ce qui renforcera votre compréhension et votre
                      confiance linguistiques. <br />
                      <br />
                      <strong>
                        Embarquez dans une Aventure d'Apprentissage et de
                        Découverte en Allemagne
                      </strong>
                    </p>
                    <div className="section__footer--link">
                      <Link to={'contacts'} className="link__btn--primary">
                        Inscrivez-vous dès Aujourd'hui !
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent />
        </main>
      </ScrollToTop>
    </>
  )
}

export default StudyTripCourses
