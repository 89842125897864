import * as React from 'react'
const SvgScreenUsers = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="screen-users"
    className="svg-inline--fa fa-screen-users fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 80.08"
    {...props}
  >
    <path
      fill="currentColor"
      d="M85.06 35V10H15v25a15.35 15.35 0 0 0-10 3.72V10.37A10.09 10.09 0 0 1 13.13.23 11 11 0 0 1 15.49 0h69a10.1 10.1 0 0 1 10.3 8.11 9.62 9.62 0 0 1 .23 2.2v28.46A15.37 15.37 0 0 0 85.06 35ZM15 80.05H5.07C2.3 80 .27 78.33 0 75.9a9.3 9.3 0 0 1 1.3-5.71 9.62 9.62 0 0 1 8.37-5c3.65-.1 7.31-.12 11 0a10 10 0 0 1 9.22 10.63A4.67 4.67 0 0 1 25.21 80c-1.59.07-3.18 0-4.77 0-1.82.06-3.65.05-5.44.05Zm70.05 0h-9.92c-2.75 0-4.77-1.72-5-4.15a9.34 9.34 0 0 1 1.29-5.71 9.64 9.64 0 0 1 8.38-5c3.65-.1 7.31-.12 11 0a10 10 0 0 1 9.2 10.62A4.66 4.66 0 0 1 95.29 80c-1.59.07-3.18 0-4.76 0-1.83.06-3.65.05-5.48.05Zm-35.05 0c-3.12 0-6.24-.07-9.37 0-3.87.12-6.33-2.48-5.41-6.78a9.85 9.85 0 0 1 9.54-8.13c3.65-.14 7.31-.12 11 0A10 10 0 0 1 64.89 74a5.7 5.7 0 0 1-.79 4 4.85 4.85 0 0 1-4.33 2c-3.26.06-6.51.05-9.77.05ZM5 50.08a10 10 0 0 1 10-10A9.93 9.93 0 1 1 15 60a10 10 0 0 1-10-9.92Zm80-10.02a10 10 0 1 1-9.93 10 10.06 10.06 0 0 1 9.93-10ZM40.05 50.07A9.94 9.94 0 1 1 50 60a10 10 0 0 1-9.95-9.93Z"
    />
  </svg>
)
export default SvgScreenUsers
