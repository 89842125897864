import { useEffect, useState } from 'react'
import { debounce, scrollSpy } from '../../utils/plugins/scrollpy'
import PropTypes from 'prop-types'
import { XLg } from 'react-bootstrap-icons'

function Sidebar({ mobile }) {
  const [spies, setSpies] = useState([])
  const [isSpies, setIsPies] = useState(false)

  useEffect(() => {
    setIsPies(true)
    const spiesElt = document.querySelectorAll('[data-spy]')
    setSpies(spiesElt)

    if (spies.length) {
      scrollSpy(spies)

      let screenH = window.innerHeight
      window.addEventListener('resize', () => {
        debounce(function () {
          if (screenH !== window.innerHeight) {
            // console.log('test')
            scrollSpy(spies)
            screenH = window.innerHeight
          }
        }, 500)

        if (window.innerWidth >= 992) {
          console.log(window.innerWidth)
          handleSidebarClose()
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpies])

  const handleSidebarClose = () => {
    if (document.getElementById('mainSidebar')) {
      document.getElementById('mainSidebar').style.left = '-300px'
      document.getElementById('contentWrapper').style.left = '0'
      document.body.style.overflow = ''
      document.getElementById('sidebarButton').style.display = ''
      document.getElementById('wrapperOverlay').style.display = ''
    }
  }

  return (
    <>
      <div className={`sidebar${mobile ? '--mobile' : ''}`} id="mainSidebar">
        <div className="sidebar--container">
          <span className="text-decoration-none border-bottom sidebar--title">
            Dans cette page
            <XLg
              className="sidebar--title-button link__btn--secondary"
              onClick={() => handleSidebarClose()}
            />
          </span>
          <div className="ul--container">
            <ul className="list-unstyled ps-0 scrollarea sidebar__ul">
              <li className="sidebar__ul--item sidebar__li">
                <a
                  href="#homeAbout"
                  className="sidebar__li--btn link-dark fl-uppercase"
                >
                  à propos
                </a>
              </li>

              <li className="sidebar__ul--item sidebar__li">
                <a
                  href="#presentation"
                  className="sidebar__li--btn btn-toggle collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#presentation-collapse"
                  aria-expanded="true"
                >
                  Pourquoi l'Allemagne ?
                </a>
                <div className="collapse show" id="presentation-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                    <li className="collapse__li">
                      <a href="#study" className="collapse__li--link link-dark">
                        Etudier en Allemagne ?
                      </a>
                    </li>

                    <li className="collapse__li">
                      <a href="#work" className="collapse__li--link link-dark">
                        Un travail et salaire fixe
                      </a>
                    </li>

                    <li className="collapse__li">
                      <a href="#stay" className="collapse__li--link link-dark">
                        Vivre en Allemagne
                      </a>
                    </li>

                    <li className="collapse__li">
                      <a
                        href="#opportunities"
                        className="collapse__li--link link-dark"
                      >
                        Des opportunités de voyages
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="sidebar__ul--item sidebar__li">
                <a
                  href="#courses"
                  className="sidebar__li--btn btn-toggle collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#courses-collapse"
                  aria-expanded="true"
                >
                  Cours & formations
                </a>
                <div className="collapse show" id="courses-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                    <li className="collapse__li">
                      <a
                        href="#german"
                        className="collapse__li--link link-dark"
                      >
                        Cours d'Allemand
                      </a>
                    </li>
                    <li className="collapse__li">
                      <a
                        href="#english"
                        className="collapse__li--link link-dark"
                      >
                        Cours d'Anglais
                      </a>
                    </li>
                    <li className="collapse__li">
                      <a
                        href="#computer"
                        className="collapse__li--link link-dark"
                      >
                        Cours d'Informatique
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="sidebar__ul--item sidebar__li">
                <a
                  type="sidebar"
                  href="#partners"
                  className="sidebar__li--btn link-dark"
                >
                  Nos partenaires
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

Sidebar.propsTypes = {
  mobile: PropTypes.bool,
}

Sidebar.defaultProps = {
  mobile: false,
}

export default Sidebar
