// import React from 'react'

import { useEffect } from 'react'
import ServicesHeaderImg from '../../assets/img/servicesheaderimg.png'
import { NavLink } from 'react-router-dom'
import { ShakeButton } from '../../utils/functions'
import {
  germanCoursesOffersData,
  jobsOffersData,
  kidsOptionsData,
  trainingOffersData,
  travelReasonsData,
} from '../../data/services/servicesData'
import MainSection from '../../components/Sections/MainSection'
import MainSectionHeader from '../../components/Sections/MainSectionHeader'
import MainSectionBody from '../../components/Sections/MainSectionBody'
import { ListItemOne } from '../../components/Cards'
import SectionFooterOne from '../../components/Sections/SectionFooterOne'
import UlComponentOne from '../../components/UlComponents/UlComponentOne'
import ComingSoon from '../../components/ComingSoon'
import SectionFooterLink from '../../components/Sections/SectionFooterLink'

function Services() {
  // const [sidebarLeft, setSidebarLeft] = useState(0)
  // const [sidebarTop, setSidebarTop] = useState()

  useEffect(() => {
    let isVisible = true
    if (isVisible) {
      let icons = document.querySelectorAll('.random-bg')
      icons.forEach((icon) => {
        icon.style.color = 'white'
        icon.style.backgroundColor = randomColor()
      })
    }

    let timeInterval = ShakeButton()

    return () => {
      isVisible = false
      clearInterval(timeInterval)
    }
  })

  const randomColor = () =>
    '#' + Math.floor(Math.random() * 16777215).toString(16)

  const handleScrollBottom = () => {
    const scrollHeight =
      document.getElementById('servicesHeader').offsetHeight - 81

    window.scrollTo({ top: scrollHeight })
  }

  return (
    <>
      <header
        id="servicesHeader"
        className="services__header page-header main-header"
      >
        <div className="page-header__container row-cols-1">
          <div className="page-header__body col-md-5">
            <h1 className="type-service page-header__body--title h1">
              Nos offres sfz
            </h1>
            <p className="page-header__body--description subtile">
              <strong className="site-branding site-name">
                Sprachfluss Zentrum - SFZ
              </strong>
              , votre destination pour une formation linguistique de qualité et
              des opportunités exceptionnelles en Allemagne.
            </p>
            <span
              className="scroll-down-box scroll-down-btn"
              id="HeaderScrollBtn"
              onClick={handleScrollBottom}
            >
              Découvrir
            </span>
          </div>
          <div className="page-header__img col-md-7">
            <img
              src={ServicesHeaderImg}
              alt="En cours"
              className="img-fluid item-image-img"
            />
            <div className="img__blendmode"></div>
          </div>
        </div>
      </header>

      <main>
        <MainSection id="offersIntro">
          <MainSectionHeader
            id="offersIntroHeader"
            classList="section-dark"
            title={
              <>
                Votre Porte vers une Meilleure Formation en Langues et des{' '}
                <strong>Opportunités en Allemagne</strong>
              </>
            }
            description={
              <>
                Chez <strong className="site-branding site-name">SFZ</strong>,
                nous sommes déterminés à vous aider à maîtriser les langues
                allemande et anglaise de manière efficace et engageante. Nous
                croyons fermement que la communication fluide dans ces langues
                peut ouvrir de nombreuses portes dans le monde d'aujourd'hui.
              </>
            }
          />
        </MainSection>

        {/*  -- 
      -----------------------------------------
      COURSES & TRAINING SECTION
      -----------------------------------------
      -- */}

        <MainSection id="serviceCourses">
          <MainSectionHeader
            id="serviceCoursesHeader"
            title={
              <>
                <strong>
                  L'Art de la Communication : <br />{' '}
                </strong>{' '}
                Explorez Nos Cours Linguistiques !
              </>
            }
            description={
              <>
                Chez <strong className="site-branding site-name">SFZ</strong>,
                nous croyons que parler différentes langues ouvre des portes
                vers un monde d'opportunités. Nos cours d'allemand et d'anglais
                sont conçus pour vous offrir une expérience d'apprentissage
                enrichissante.
                <br />
                <br />
                <br />
                <strong>
                  Explorez nos offres de formation linguistique et découvrez
                  comment SFZ peut vous aider à atteindre vos objectifs
                  linguistiques.
                </strong>
              </>
            }
          />

          <MainSectionBody
            id="serviceCoursesBody"
            containerClassList={'container container-fluid'}
          >
            <UlComponentOne classList="section__ul card--grid" id="coursesList">
              {germanCoursesOffersData.map(
                ({ id, image, description, link }, index) => (
                  <ListItemOne
                    id={id}
                    iconId={id + '-icon'}
                    image={image}
                    key={`${id}-${index}`}
                    description={description}
                    spanImageClassList={'random-bg'}
                  >
                    <div className="btn__link">
                      <NavLink
                        to={`/courses/${link.to}`}
                        className="link__btn--primary"
                      >
                        {link.text}
                      </NavLink>
                    </div>
                    <div className="btn__link">
                      <NavLink
                        to={`/contacts`}
                        className="link__btn--secondary"
                      >
                        Nous contacter
                      </NavLink>
                    </div>
                  </ListItemOne>
                )
              )}
            </UlComponentOne>
          </MainSectionBody>
        </MainSection>

        {/*  -- 
      -----------------------------------------
      FORMATION IN GERMANY SECTION
      -----------------------------------------
      -- */}

        <MainSection id="serviceFormation">
          <MainSectionHeader
            id="serviceFormationHeader"
            title={'Contrats de formation en Allemagne'}
            description={
              <>
                A Sprachfluss Zentrum, vous obtenez un contrat de formation dans
                les domaines suivants :
              </>
            }
          />

          <MainSectionBody
            id="serviceFormationBody"
            containerClassList="container container-fluid"
          >
            <UlComponentOne
              classList="section__ul card--grid"
              id="formationList"
            >
              {trainingOffersData ? (
                trainingOffersData.map(
                  ({ id, image, description, link }, index) => (
                    <ListItemOne
                      id={id}
                      icon
                      iconId={id + '-icon'}
                      image={image}
                      key={`${id}-${index}`}
                      description={description}
                      spanImageClassList={'random-bg'}
                    >
                      {link && (
                        <div className="btn__link">
                          <NavLink
                            to={`${link.to}`}
                            className="link__btn--primary"
                          >
                            {link.text}
                          </NavLink>
                        </div>
                      )}
                      <div className="btn__link">
                        <NavLink
                          to={`/contacts`}
                          className="link__btn--secondary"
                        >
                          Nous contacter
                        </NavLink>
                      </div>
                    </ListItemOne>
                  )
                )
              ) : (
                <ComingSoon />
              )}
            </UlComponentOne>
          </MainSectionBody>

          <SectionFooterOne
            id="serviceFormationFooter"
            bgType={'secondary'}
            description={
              <>
                Pendant la formation, vous percevez une somme d’argent qui vous
                permet de subvenir à tous vos besoins et à l’issue de cette
                formation, vous obtenez un travail bien rémunéré.
              </>
            }
          />
        </MainSection>

        {/*  -- 
      -----------------------------------------
      WORK IN GERMANY SECTION
      -----------------------------------------
      -- */}

        <MainSection id="workOffers">
          <MainSectionHeader
            id="workOffersHeader"
            title="Contrats de travail en Allemagne"
            description={
              <>
                Vous détenez votre certificat de langue (A2, B1, B2, C1) et
                désirez <strong>travailler en Allemagne</strong>, les
                partenaires de Sprachfluss Zentrum vous trouvent des contrats
                dans un bref délais et dans l’un des domaines suivants :
              </>
            }
          />
          <MainSectionBody
            id="workOffersBody"
            containerClassList="container container-fluid"
          >
            <UlComponentOne classList="section__ul card--grid" id="jobList">
              {jobsOffersData ? (
                jobsOffersData.map(
                  ({ id, image, description, link }, index) => (
                    <ListItemOne
                      id={id}
                      // icon
                      iconId={id + '-icon'}
                      image={image}
                      key={`${id}-${index}`}
                      description={description}
                      spanImageClassList={'random-bg'}
                    >
                      {link && (
                        <div className="btn__link">
                          <NavLink
                            to={`${link.to}`}
                            className="link__btn--tertiary"
                          >
                            {link.text}
                          </NavLink>
                        </div>
                      )}
                      <div className="btn__link">
                        <NavLink
                          to={`/contacts`}
                          className="link__btn--secondary"
                        >
                          Nous contacter
                        </NavLink>
                      </div>
                    </ListItemOne>
                  )
                )
              ) : (
                <ComingSoon />
              )}
            </UlComponentOne>
          </MainSectionBody>
          <SectionFooterOne
            id="workOffersFooter"
            bgType={'secondary'}
            description={
              <>
                Ce contrat de travail est bien payé. Ce qui va vous permettre de
                vivre aisément et de faire des économies.
              </>
            }
          />
        </MainSection>

        {/*  -- 
      -----------------------------------------
      SUPPORT SECTION
      -----------------------------------------
      -- */}

        <MainSection id="supportOffers">
          <MainSectionHeader
            id="supportOffersHeader"
            title="Nous vous suivons et nous vous encadrons"
            description="Que vous voyagiez pour..."
          />
          <MainSectionBody containerClassList="container container-fluid">
            <UlComponentOne
              classList="section__ul card--grid align-items-stretch"
              id="supportList"
            >
              {travelReasonsData ? (
                travelReasonsData.map(({ id, image, description }, index) => (
                  <ListItemOne
                    id={id}
                    // icon
                    iconId={id + '-icon'}
                    image={image}
                    hasLink={false}
                    key={`${id}-${index}`}
                    description={description}
                    spanImageClassList={'random-bg'}
                  >
                    <div className="btn__link">
                      <NavLink
                        to={`/contacts`}
                        className="link__btn--secondary"
                      >
                        Nous contacter
                      </NavLink>
                    </div>
                  </ListItemOne>
                ))
              ) : (
                <ComingSoon />
              )}
            </UlComponentOne>
          </MainSectionBody>
          <SectionFooterOne
            id="supportOffersHeader"
            description={
              <>
                l’équipe Sprachfluss est là pour vous accompagner et vous aider
                jusqu’à l’obtention de votre visa et votre arriver en Allemagne.
              </>
            }
            bgType="primary"
            link={{ path: '/contacts', text: 'Nous contacter' }}
            className="section__footer bg--primary"
          />
        </MainSection>

        {/*  -- 
      -----------------------------------------
      CHILDREN SECTION
      -----------------------------------------
      -- */}

        <MainSection id="childrenOffers">
          <MainSectionHeader
            id="childrenOffersHeader"
            title="L’avenir de vos enfants nous intéresse !"
            description={
              <>
                Les enfants aussi trouvent leur compte à Sprachfluss Zentrum (
                <em>
                  de <strong>7 à 17 ans</strong>
                </em>
                ). <br />
                Ceci leurs permet d’anticiper sur leurs futurs tout en leurs
                permettant de :
              </>
            }
          />

          <MainSectionBody
            id="childrenOffersBody"
            containerClassList="container container-fluid"
          >
            <UlComponentOne
              classList="section__ul card--grid align-items-stretch"
              id="childrenOffersList"
            >
              {kidsOptionsData ? (
                kidsOptionsData.map(({ id, image, description }, index) => (
                  <ListItemOne
                    id={id}
                    icon
                    iconId={id + '-icon'}
                    image={image}
                    hasLink={false}
                    key={`${id}-${index}`}
                    description={description}
                    spanImageClassList={'random-bg'}
                  >
                    <div className="btn__link">
                      <NavLink
                        to={`/contacts`}
                        className="link__btn--secondary"
                      >
                        Nous contacter
                      </NavLink>
                    </div>
                  </ListItemOne>
                ))
              ) : (
                <ComingSoon />
              )}
            </UlComponentOne>
            <SectionFooterLink
              id="childrenOffersBodyLink"
              type={'secondary'}
              link={{ path: '/contacts/7', text: 'Nous contacter' }}
            />
          </MainSectionBody>
        </MainSection>
      </main>
    </>
  )
}

export default Services
