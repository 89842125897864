import React from 'react'
import PropTypes, { element, string } from 'prop-types'
import SectionFooterLink from './SectionFooterLink'

function SectionFooterOne({
  id,
  title,
  description,
  bgType,
  link,
  classList,
  pClassList,
  linkParentClassList,
  linkClassList,
}) {
  return (
    <>
      {/*
        -----------------------------------------
        SECTION FOOTER ONE
        -----------------------------------------
      */}

      <div
        className={`section__footer bg--${bgType}${
          classList ? ' ' + classList : ''
        }`}
        id={id}
      >
        <div className="section__footer--container container-md container-fluid">
          {title && (
            <h3 className="section__footer--title px-4 section--h3 h3">
              {title}
            </h3>
          )}
          {description && (
            <p
              className={`section__footer--text${
                pClassList ? ' ' + pClassList : ''
              }`}
            >
              {description}
            </p>
          )}
          {link && (
            <SectionFooterLink
              id={`${id}Link`}
              link={{
                path: link.path,
                text: link.text,
              }}
              type={bgType}
              classList={linkParentClassList}
              linkClassList={linkClassList}
            />
          )}
        </div>
      </div>
    </>
  )
}

SectionFooterOne.defaultProps = {
  bgType: 'primary',
}

SectionFooterOne.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  classList: PropTypes.string,
  pClassList: PropTypes.string,
  linkClassList: PropTypes.string,
  linkParentClassList: PropTypes.string,
  link: PropTypes.shape({
    path: string,
    text: string,
  }),
  description: PropTypes.oneOfType([string, element]),
  bgType: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
}

export default SectionFooterOne
