import pic_7 from '../../assets/img/pic_7.jpg'
import pic_5 from '../../assets/img/pic_5.jpg'
import study from '../../assets/img/study.jpg'

export const carouselItemsData = [
  {
    id: 'carouselItem1',
    itemClass: 'custom-carousel-item',
    image: {
      id: 'itemImage1',
      path: pic_7,
      alt: "Anticiper demain aujourd'hui",
      classList: 'img-fluid item-image-img',
      parentClass: 'item-image',
      blendMode: true,
    },
    body: {
      id: 'itemBody1',
      classList: 'item__body',
      title: {
        id: '',
        classList: 'item__body--title',
        text: 'Préparer son après Bac',
      },
    },
    caption: {
      id: 'itemCaption1',
      classList: 'item__caption',
      title: {
        id: 'itemCaptionTitle1',
        classList: 'item__caption--title',
        text: "Anticiper demain aujourd'hui !",
      },
      description: {
        id: '',
        classList: 'item__caption--description',
        text: 'Notre centre de formation a fait ses preuves en matière de réussite post-bac.',
      },
      button: {
        id: 'homeCarouselButton1',
        classList: 'caption__btn',
        contact: {
          id: 'homeCarouselButtonContact1',
          classList: 'caption__btn--primary',
          path: 'contacts',
          text: 'Nous contacter',
        },
        more: {
          id: 'homeCarouselButtonMore1',
          classList: 'caption__btn--secondary',
          path: 'about-us',
          text: 'En savoir plus',
        },
      },
    },
  },
  {
    id: 'carouselItem2',
    itemClass: 'custom-carousel-item',
    image: {
      id: 'itemImage2',
      path: study,
      alt: 'Etudier et travailler en Allemagne',
      classList: 'img-fluid item-image-img',
      parentClass: 'item-image',
      blendMode: true,
    },
    body: {
      id: 'itemBody2',
      classList: 'item__body',
      title: {
        id: '',
        classList: 'item__body--title',
        text: 'Planifier ses études en Allemagne',
      },
    },
    caption: {
      id: 'itemCaption2',
      classList: 'item__caption',
      title: {
        id: 'itemCaptionTitle2',
        classList: 'item__caption--title',
        text: 'Etudier et travailler en Allemagne',
      },
      description: {
        id: '',
        classList: 'item__caption--description',
        text: "Préparez-vous à une expérience d'apprentissage inoubliable avec nos cours spécialement conçus pour les <br/><strong>voyageurs d'étude en Allemagne</strong> !",
      },
      button: {
        id: 'homeCarouselButton2',
        classList: 'caption__btn',
        contact: {
          id: 'homeCarouselButtonContact2',
          classList: 'caption__btn--primary',
          path: 'contacts',
          text: 'Nous contacter',
        },
        more: {
          id: 'homeCarouselButtonMore2',
          classList: 'caption__btn--secondary',
          path: 'about-us',
          text: 'En savoir plus',
        },
      },
    },
  },
  {
    id: 'carouselItem3',
    itemClass: 'custom-carousel-item',
    image: {
      id: 'itemImage3',
      path: pic_5,
      alt: "Obtenez une Bourse d'étude",
      classList: 'img-fluid item-image-img',
      parentClass: 'item-image',
      blendMode: true,
    },
    body: {
      id: 'itemBody3',
      classList: 'item__body',
      title: {
        id: '',
        classList: 'item__body--title',
        text: 'Voyagez très facillement',
      },
    },
    caption: {
      id: 'itemCaption3',
      classList: 'item__caption',
      title: {
        id: 'itemCaptionTitle3',
        classList: 'item__caption--title',
        text: "Obtenez une Bourse d'étude",
      },
      description: {
        id: '',
        classList: 'item__caption--description',
        text: "Réaliser vos rêves et vos ambitions. <br/>Couvrez vos frais de scolarité et à accédez aux meilleures opportunités. <br/><strong>Demandez dès aujourd'hui votre bourse d'études !</strong>",
      },
      button: {
        id: 'homeCarouselButton3',
        classList: 'caption__btn',
        contact: {
          id: 'homeCarouselButtonContact3',
          classList: 'caption__btn--primary',
          path: 'contacts',
          text: 'Nous contacter',
        },
        more: {
          id: 'homeCarouselButtonMore3',
          classList: 'caption__btn--secondary',
          path: 'about-us',
          text: 'En savoir plus',
        },
      },
    },
  },
]
