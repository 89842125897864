import React, { useEffect } from 'react'
import { ChevronDoubleDown, ThreeDotsVertical } from 'react-bootstrap-icons'
import CoursesPicture from '../../assets/img/sfz-cours.png'
import {
  ComputerCoursesCards,
  EnglishCoursesCards,
  GermanCoursesCards,
} from '../../components/Courses'
import Sidebar from '../../components/Sidebar'
import HomeNavbar from '../../components/HomeNavbar'
import FooterComponent from '../../components/Footers'
import { ShakeButton } from '../../utils/functions'
import ScrollToTop from '../../components/ScrollToTop'
import SectionItemOne from '../../components/Sections/SectionItemOne'

const sectionItemsData = [
  {
    id: 'st-germancourses',
    title: "Cours d'Allemand",
    description:
      "Nos cours d'allemand sont organisés de manière progressive pour répondre aux besoins des apprenants de tous niveaux.",
    children: <GermanCoursesCards />,
  },
  {
    id: 'st-englishcourses',
    title: "Cours d'Anglais",
    description:
      "Nos cours d'anglais intensifs sont également disponibles pour vous aider à atteindre le niveau de compétence requis dans les pays anglophones.",
    children: <EnglishCoursesCards />,
  },
  {
    id: 'st-computingcourses',
    title: "Cours d'Informatique",
    description: '',
    children: <ComputerCoursesCards />,
  },
]

function Courses() {
  useEffect(() => {
    const intervalId = ShakeButton()

    return () => clearInterval(intervalId)
  })

  const handleScrollBottom = () => {
    const scrollHeight =
      document.getElementById('coursesHeader').offsetHeight - 81

    window.scrollTo({ top: scrollHeight })
  }

  const handleSidebarShow = () => {
    document.getElementById('mainSidebar').style.left = '00px'
    document.getElementById('contentWrapper').style.left = '300px'
    document.getElementById('sidebarButton').style.display = 'none'
    document.getElementById('wrapperOverlay').style.display = 'block'
    document.body.style.overflow = 'hidden'
  }

  return (
    <>
      <ScrollToTop>
        <Sidebar mobile={true} />
        <main className="main-wrapper" id="contentWrapper">
          <div className="overlay" id="wrapperOverlay"></div>
          <HomeNavbar />
          <header id="coursesHeader" className="blendmode-header main-header">
            <div className="blendmode-header__img">
              <img
                src={CoursesPicture}
                alt="En cours"
                className="img-fluid item-image-img"
              />
              <div className="img__blendmode"></div>
            </div>
            <div className="blendmode-header__body">
              <div className="blendmode-header__body--title">
                <h1 className="blendmode-header__body--title-h1 h1">
                  Sprachfluss Zentrum - Cours
                </h1>
                <p className="blendmode-header__body--subtile subtile">
                  Toutes nos catégories de cours, de l'enfant à l'adulte...
                </p>
              </div>
              <div className="blendmode-header__body--description my-md-5">
                <span className="blendmode-header__body--description-text p-3 mt-3">
                  Des cours très avancés et bien adaptés vous permettant
                  d'apprendre en toute simplicité
                </span>
              </div>
            </div>
            <div className="scroll-down-box">
              <ChevronDoubleDown
                id="HeaderScrollBtn"
                onClick={handleScrollBottom}
                className="scroll-down-btn"
                size="2.5em"
              />
            </div>
          </header>

          <section className="sections__wrapper py-4" id="coursesSection">
            <div className="sections__container container-lg container-fluid">
              <Sidebar />
              <div
                className="sidebar__button link__btn--secondary"
                id="sidebarButton"
              >
                <ThreeDotsVertical
                  className="sidebar__button--show"
                  onClick={handleSidebarShow}
                />
              </div>

              {/*  -- 
              -----------------------------------------
              COURSES SECTION
              -----------------------------------------
              -- */}

              <div className="courses__section px-4" id="courses" data-spy>
                <div className="section-header">
                  <h2 className="section__title section--h2 h2">
                    Nos cours & formations
                  </h2>
                  <p className="section__description text-center text-lg-start">
                    Que vous soyez un étudiant, un professionnel en quête
                    d'élargir ses horizons ou une famille cherchant à offrir à
                    ses enfants une opportunité d'apprentissage exceptionnelle,
                    nos cours sont conçus pour répondre à vos besoins
                    spécifiques.
                  </p>
                </div>

                <div className="section__body">
                  {sectionItemsData.map((item) => (
                    <SectionItemOne
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      description={item.description}
                    >
                      <div className="course__body justify-content-center justify-content-md-start">
                        {item.children}
                      </div>
                    </SectionItemOne>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <FooterComponent />
        </main>
      </ScrollToTop>
    </>
  )
}

export default Courses
