import * as React from 'react'
const SvgTruckContainer = (props) => (
  <svg
    aria-hidden="true"
    data-prefix="fas"
    data-icon="truck-container"
    className="svg-inline--fa fa-truck-container fa-2x"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 70.03"
    {...props}
  >
    <g fill="currentColor">
      <path d="M70 57.56H45c-.42 5.73-2.92 10-8.58 11.77s-10.24.24-13.95-4.25C17.53 71.8 8.16 71 3.64 66.48a12.9 12.9 0 0 1-.06-17.82 12.9 12.9 0 0 1 18.9 1.2 3.57 3.57 0 0 0 .28-.27 12.31 12.31 0 0 1 8.85-4.43 11.74 11.74 0 0 1 10.46 4.41 1.21 1.21 0 0 0 1 .49h24.48V21.9a6.28 6.28 0 0 1 .1-1.3 3.61 3.61 0 0 1 3.76-3.06c3.76 0 7.51-.06 11.26 0a8.67 8.67 0 0 1 6.78 3.33c3.08 3.74 6.12 7.5 9.19 11.26a5.79 5.79 0 0 1 1.36 3.79v17.15a4.54 4.54 0 0 1-4.12 4.36c-.84.08-.82.09-.88.89a12 12 0 0 1-9.2 11.24A12.47 12.47 0 0 1 74.31 67a11.76 11.76 0 0 1-4.09-7.63c-.09-.58-.13-1.17-.22-1.81ZM75 25v10h16.28l-.56-.72c-2-2.52-4-5-6.09-7.56C83 24.65 83.67 25 81 25h-6ZM7.49 57.18a4.84 4.84 0 0 0 4.57 5.37 5 5 0 0 0 5.38-4.14c.39-3.44-1.72-5.64-4.46-5.87a4.83 4.83 0 0 0-5.49 4.64Zm30 .75A4.79 4.79 0 0 0 33 52.55a5 5 0 1 0-.83 10 4.79 4.79 0 0 0 5.3-4.62Zm50.05-.69a4.84 4.84 0 0 0-5.54-4.7c-2.73.23-4.85 2.42-4.47 5.87a4.94 4.94 0 0 0 5.32 4.14 4.84 4.84 0 0 0 4.67-5.31Z" />
      <path d="M31.26 0H51a11 11 0 0 1 9.85 5.32 11.52 11.52 0 0 1 1.66 6.25v28.08a3.7 3.7 0 0 1-3 4 3.65 3.65 0 0 1-4.39-3.21 9.47 9.47 0 0 1-.12-1.07V11.91a7.26 7.26 0 0 0-.15-1.47 3.65 3.65 0 0 0-3.63-2.95H11.54A3.84 3.84 0 0 0 8.21 9a4.59 4.59 0 0 0-.77 2.81v27.7a4 4 0 0 1-1.21 3.23 3.64 3.64 0 0 1-3.84.72 3.54 3.54 0 0 1-2.34-3 6.55 6.55 0 0 1 0-.89V11.68A11.46 11.46 0 0 1 2.65 4a11.23 11.23 0 0 1 8.9-4h19.71Z" />
      <path d="M13.74 26.24v-9.53a6.56 6.56 0 0 1 .26-1.52 3.48 3.48 0 0 1 3.59-2.63 3.54 3.54 0 0 1 3.54 3 8.15 8.15 0 0 1 .11 1.3v19a3.92 3.92 0 0 1-1.43 3.31A3.7 3.7 0 0 1 13.87 37a7.15 7.15 0 0 1-.12-1.3c-.01-3.15-.01-6.3-.01-9.46Zm27.52.02v-9.59a6.68 6.68 0 0 1 .21-1.46 3.48 3.48 0 0 1 3.58-2.65 3.53 3.53 0 0 1 3.56 3.06 7.13 7.13 0 0 1 .11 1.3v19a3.67 3.67 0 0 1-3.22 4A3.54 3.54 0 0 1 41.4 37a6.37 6.37 0 0 1-.13-1.3q-.01-4.7-.01-9.44Zm-6.26.07v9.64a3.7 3.7 0 0 1-3 3.93 3.65 3.65 0 0 1-4.38-3.28 5.74 5.74 0 0 1 0-.71V16.68a5.77 5.77 0 0 1 .36-2 3.52 3.52 0 0 1 3.58-2.14 3.58 3.58 0 0 1 3.43 3.28 8.65 8.65 0 0 1 .05 1Z" />
    </g>
  </svg>
)
export default SvgTruckContainer
