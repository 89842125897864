import React from 'react'
import PropTypes, { array, element, object, string } from 'prop-types'

function MainHeader({
  id,
  className,
  title,
  subtitle,
  description,
  headerContanierClassName,
  headerBodyClassName,
  headerImgClassName,
  handleScrollBottom,
  image,
  blendMode,
  links,
}) {
  return (
    <>
      {/*
        -----------------------------------------
        MAIN HEADER
        -----------------------------------------
      */}

      <header
        id={id}
        className={`main-header${className ? ' ' + className : ''}`}
      >
        <div
          className={`page-header__container${
            headerContanierClassName ? ' ' + headerContanierClassName : ''
          }`}
        >
          <div
            className={`page-header__body${
              headerBodyClassName ? ' ' + headerBodyClassName : ''
            }`}
          >
            {subtitle && (
              <h2 className="page-header__body--subtitle h2">{subtitle}</h2>
            )}
            <h1 className="page-header__body--title h1">{title}</h1>
            <p className="page-header__body--description subtile">
              {description}
            </p>
            {handleScrollBottom && (
              <span
                className="scroll-down-box scroll-down-btn"
                id="HeaderScrollBtn"
                onClick={handleScrollBottom}
              >
                Découvrir
              </span>
            )}
          </div>
          {links && <div className="img__blendmode"></div>}
          <div
            className={`${headerImgClassName ? ' ' + headerImgClassName : ''}`}
          >
            <img src={image.src} alt={image.alt} className={image.className} />
            {blendMode && <div className="img__blendmode"></div>}
          </div>
        </div>
      </header>
    </>
  )
}

MainHeader.defaultProps = {
  blendMode: true,
}

MainHeader.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([element, string]).isRequired,
  subtitle: PropTypes.oneOfType([element, string]),
  description: PropTypes.oneOfType([element, string]).isRequired,
  headerContanierClassName: PropTypes.string,
  headerBodyClassName: PropTypes.string,
  headerImgClassName: PropTypes.string,
  handleScrollBottom: PropTypes.func,
  image: PropTypes.shape({
    src: string.isRequired,
    alt: string,
    className: string,
  }).isRequired,
  blendMode: PropTypes.bool,
  links: PropTypes.oneOfType([array, object]),
}

export default MainHeader
