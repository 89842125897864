import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import appColors from './appColors'

export const StyledLink = styled(Link)`
  ${({ type }) =>
    type === 'default'
      ? `
      outline: none;
      font-weight: 700;
      font-size: 1.2rem;
      text-decoration: none;
      color: ${appColors.lightGray};
      transition: 0.4s ease-in-out;
      &:hover {
        color: ${appColors.orange};
      }
    `
      : type === 'home'
      ? `
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: ${appColors.secondary} !important;
      }
    `
      : type === 'navbar'
      ? `
        font-weight: 400;
        font-size: 1.3rem;
        color: ${appColors.darkGray};
        &:hover {
          color: ${appColors.primary};
          box-shadow: 0px -5px 0 0 inset ${appColors.primary};
          background-color: ${appColors.backgroundLight};
        }
        &:active {
          background-color: #fce5bb;
          color: #eb3905;
          border-radius: 15px;
        }
    `
      : type === 'primary'
      ? `
      display: flex;
      border-radius: 30px;
      font-size: 1.2rem;
      padding: 15px 25px;
      color: ${appColors.lightBlue};
      background-color: ${appColors.primary};
      &:hover {
        color: white;
      }
    `
      : type === 'secondary'
      ? `
      display: flex;
      border-radius: 30px;
      font-size: 1.2rem;
      padding: 15px 25px;
      color: ${appColors.lightGray};
      background-color: ${appColors.darkGray};
      &:hover {
        color: white;
      }`
      : type === 'sidebar'
      ? `
      font-size: 1em;
      font-weight: 400;
      padding-left: .4em !important;`
      : ''}
`

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
 
    to {
    transform: rotate(360deg);
    }
`

const zoom = keyframes`
    from {
        transform: scale(1);
    }
 
    50% {
    transform: scale(1.5);
    }
 
    100% {
    transform: scale(1);
    }
`

export const Loader = styled.div`
  padding: 10px;
  background-image: linear-gradient(
    to right,
    ${appColors.primary},
    ${appColors.secondary}
  );
  border-radius: 100%;
  animation: ${rotate} 1s infinite linear;
  height: 50px;
  width: 50px;
`

export const LoaderIn = styled.div`
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 100%;
  animation: ${zoom} 1s infinite linear;
  background-color: ${appColors.backgroundLight};
`

export const Loader2 = styled.div`
  padding: 20px;
  border: 4px solid ${appColors.primary};
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-radius: 22px;
  animation: ${rotate} 1s infinite linear;
  height: 0;
  width: 0;
`

export const Waiting = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.6rem;
  font-weight: 700;
  color: ${appColors.textColor};
`

export const ErrorTitle = styled.h1`
  color: ${appColors.secondary};
`

export const ErrorMessage = styled.p`
  color: ${appColors.textColor};
  background-color: ${appColors.orangeLight};
`

export const MainTitle = styled.h1`
  color: ${appColors.primary};
`

export const SecondTitle = styled.h2`
  color: ${appColors.secondary};
`

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
`

export const ErrorBox = styled.div`
  max-width: 824px;
`
