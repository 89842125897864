import React from 'react'
import PropTypes from 'prop-types'

function MainSection({ id, classList, children }) {
  return (
    <>
      {/*
        -----------------------------------------
        MAIN SECTION
        -----------------------------------------
      */}
      <div className={`section${classList ? ' ' + classList : ''}`} id={id}>
        {children}
      </div>
    </>
  )
}

MainSection.propTypes = {
  id: PropTypes.string.isRequired,
  classList: PropTypes.string,
}

export default MainSection
