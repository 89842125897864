export const NavLinks = [
  {
    path: '/',
    text: 'Accueil',
    itemClassName: '',
    linkClassName: 'nav--link',
  },
  {
    path: '/offers',
    text: 'Nos offres',
    itemClassName: '',
    linkClassName: 'nav--link',
  },
  {
    path: '/courses',
    text: 'Cours',
    itemClassName: '',
    disabled: false,
    linkClassName: 'nav--link',
  },
  {
    path: '/gallery',
    text: 'Galeries',
    itemClassName: '',
    disabled: true,
    linkClassName: 'nav--link',
  },
  {
    path: '/about-us',
    text: 'A propos',
    itemClassName: '',
    linkClassName: 'nav--link',
  },
  {
    path: '/contacts',
    text: 'Contacts',
    itemClassName: '',
    linkClassName: 'nav--link',
  },
]
