import React from 'react'
import PropTypes, { element, string } from 'prop-types'
import { Link } from 'react-router-dom'

function FeatureItemOne({
  id,
  order,
  title,
  subTitle,
  description,
  dataLenght,
  index,
  src,
  link,
}) {
  const indexSum = index + 1

  return (
    <>
      {/*
        -----------------------------------------
        FEATURE ITEM ONE
        -----------------------------------------
      */}

      <div className="feature" id={id} data-spy>
        <div className={`feature__img rounded-pill${order ? ' ' + order : ''}`}>
          <img
            className="transition-(3) feature__img--image mx-auto"
            src={src.path}
            alt={src.alt}
          />
        </div>
        <div className="feature__body">
          <h3 className="feature--heading">
            {title} <br />
            <strong className="fst-italic">{subTitle}</strong>
          </h3>
          <p className="">{description}</p>
          <div className="link__btn">
            {link.target ? (
              <a
                className="link__btn--secondary"
                href={link.path}
                role="button"
                target={'_blank'}
                rel="noreferrer"
              >
                {link.text} &raquo;
              </a>
            ) : (
              <Link
                className="link__btn--secondary"
                to={link.path}
                role="button"
              >
                {link.text} &raquo;
              </Link>
            )}
          </div>
        </div>
      </div>
      {dataLenght > indexSum ? <hr className="hr--divider" /> : <></>}
    </>
  )
}

FeatureItemOne.propTypes = {
  id: PropTypes.string.isRequired,
  order: PropTypes.string,
  index: PropTypes.number,
  dataLenght: PropTypes.number,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  src: PropTypes.objectOf(string.isRequired).isRequired,
  link: PropTypes.objectOf(string).isRequired,
  description: PropTypes.oneOfType([string, element]),
}

FeatureItemOne.defaultProps = {
  link: {
    path: '#',
    text: 'En sovoir plus',
    target: '',
  },
  index: null,
  dataLenght: null,
}

export default FeatureItemOne
