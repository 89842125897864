import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
  },
  avatar: {
    width: 150,
    height: 150,
    marginBottom: theme.spacing(3),
    borderRadius: '50%',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  name: {
    color: theme.palette.primary.main,
  },
  role: {
    color: theme.palette.secondary.main,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  status: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap',
  },
  details: {
    marginTop: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  titleText: {
    marginLeft: theme.spacing(1),
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
}))
