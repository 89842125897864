import HomeHeader from '../../components/Headers'
import logoDark from '../../assets/img/sfzLogo-notext.png'
import TargetImg from '../../assets/img/target.png'
import GroupImg from '../../assets/img/group-1.png'
import study from '../../assets/img/study.jpg'
import work from '../../assets/img/work.jpg'
import stay from '../../assets/img/stay.jpg'
import sfzwithbg from '../../assets/img/sfzwithbg3d.jpg'
import appColors from '../../utils/style/appColors'
// import Sidebar from '../../components/Sidebar'

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import HomeNavbar from '../../components/HomeNavbar'
import FooterComponent from '../../components/Footers'
import ScrollToTop from '../../components/ScrollToTop'
import { travelOpportunityDetails } from '../../data/travels/travelsData'
import { MainCard } from '../../components/Cards'
import { coursesInformations } from '../../data/courses/coursesData'
import ComingSoon from '../../components/ComingSoon'
import FeatureItemOne from '../../components/Features/FeatureItemOne'
import SectionHeaderOne from '../../components/Sections/SectionHeaderOne'
import SectionFooterOne from '../../components/Sections/SectionFooterOne'
import SectionBodyOne from '../../components/Sections/SectionBodyOne'
import MainSection from '../../components/Sections/MainSection'
import SectionFooterLink from '../../components/Sections/SectionFooterLink'
// import { useState } from 'react'

const AboutLink = styled(Link)`
  background-color: ${appColors.orange};
  border-color: ${appColors.orange};
`

const featuresData = [
  {
    id: 'wg-study',
    order: '',
    title: 'Etudier en Allemagne ?',
    subTitle: 'Un pays pas comme les autres !',
    description: (
      <>
        L’Allemagne est la{' '}
        <strong>première puissance économique européenne</strong> et sa langue
        est la deuxième la plus parlée au sein de l’Union Européenne. Faire une
        partie de ses études dans le pays de Goethe vous ouvre des portes pour
        trouver un emploi aussi bien en France qu’en Allemagne ou dans d’autres
        pays européens.
      </>
    ),
    src: {
      path: study,
      alt: 'study in Germany',
    },
    link: {
      path: 'https://traductions-assermentees.com/blog/6-bonnes-raisons-de-partir-etudier-en-allemagne',
      text: 'En sovoir plus',
      target: '_blank',
    },
  },
  {
    id: 'wg-work',
    order: 'order-md-2',
    title: 'Un travail et salaire fixe.',
    subTitle: 'Travaillez dans de bonnes conditions dans un pays sûr.',
    description: (
      <>
        L’économie allemande manque de main d’œuvre qualifiée, des{' '}
        <em>centaines de milliers d’emplois sont vacants</em>. Depuis mars 2020,
        la nouvelle loi sur l’immigration des travailleurs qualifiés élargit
        donc les possibilités de salariés et salariées originaires de pays
        non-européens de venir en Allemagne. Le message est clair :{' '}
        <em>
          <strong>
            la main d’œuvre qualifiée est la bienvenue pour venir et rester en
            Allemagne
          </strong>
        </em>
        .
      </>
    ),
    src: {
      path: work,
      alt: 'work in Germany',
    },
    link: {
      path: 'https://www.deutschland.de/fr/topic/economie/loi-relative-a-limmigration-de-main-doeuvre-qualifiee-travailler-en-allemagne',
      text: 'En sovoir plus',
      target: '_blank',
    },
  },
  {
    id: 'wg-stay',
    order: '',
    title: 'Vivre en Allemagne,',
    subTitle: 'Une vie simplifée et relaxante...',
    description: (
      <>
        La vie en Allemagne est riche et diversifiée. Qu'il s'agisse de{' '}
        <strong>la famille</strong>, du{' '}
        <strong>logement ou des assurances</strong>, familiarisez-vous avec
        l'Allemagne et découvrez comment créer des conditions optimales pour
        votre séjour. <strong>Le pays de Goethe</strong> regorge de trésors
        culturels à découvrir. Avec de nombreuses villes variées, les musées et
        autres centres culturels attirent chaque année de nombreux visiteurs.
      </>
    ),
    src: {
      path: stay,
      alt: 'stay in Germany',
    },
    link: {
      path: 'https://www.germany.travel/fr/villes-et-culture/overview.html',
      text: 'En sovoir plus',
      target: '_blank',
    },
  },
  {
    id: 'wg-opportunities',
    order: 'order-md-2',
    title: 'Des opportunités de voyages.',
    subTitle: 'Voyagez quand vous voulez !!!',
    description: (
      <>
        Sprachfluss Zentrum, en partenariat avec plusieurs{' '}
        <strong>structures allemandes</strong>, vous offre des opportunités de
        voyages pour l'Allemagne et vous accompagne jusqu'à votre intégration
        dans la vie active.
      </>
    ),
    src: {
      path: sfzwithbg,
      alt: 'opportunities in Germany',
    },
    link: {
      path: 'about-us',
      text: 'En sovoir plus',
      target: '',
    },
  },
]

function Home() {
  // const [sidebarLeft, setSidebarLeft] = useState(0)

  return (
    <>
      <ScrollToTop>
        <HomeNavbar />
        <HomeHeader />

        {/*  -- 
      -----------------------------------------
      HOME ABOUT SECTION
      -----------------------------------------
      -- */}

        <section className="section home--about" id="homeAbout" data-spy>
          <div className="home--about-container container container-fluid">
            {/* -- About-team messaging and featurettes
================================================== --  */}
            <div className="card--grid">
              {/* -- /.01 -- */}
              <div id="home-about-1" className="home--about-item">
                <div className="about__img rounded-circle text-center row mx-auto">
                  <img
                    src={logoDark}
                    className="about__img--img img-fluid m-auto"
                    alt=""
                    id="aboutLogo"
                  />
                </div>
                <div className="about__body">
                  <h2 className="about__body--title">SPRACHFLUSS ZENTRUM</h2>
                  <p className="about__body--text">
                    SprachFluss Zentrum est un centre linguistique de langue
                    allemande facilitant l'obtention d'un visa d'étude pour
                    l'Allemagne et offrant une pléthore d'opportunités de voyage
                    grâce à ses partenaires.
                  </p>
                </div>
                <div className="about__btn">
                  <AboutLink
                    className="about__btn--link btn btn-primary"
                    to="about-us#aboutCompagny"
                    role="button"
                  >
                    En sovoir plus &raquo;
                  </AboutLink>
                </div>
              </div>
              {/* -- /.02 -- */}
              <div id="home-about-2" className="home--about-item">
                <div className="about__img rounded-circle text-center row mx-auto">
                  <img
                    src={GroupImg}
                    className="about__img--img img-fluid m-auto"
                    alt=""
                  />
                </div>
                <div className="about__body">
                  <h2 className="about__body--title">Pourquoi nous !</h2>
                  <p className="about__body--text">
                    Vous avez le BEPC, le Probatoire et ou le Bac, ou encore
                    d’autres diplômes d’études supérieures, vous êtes
                    travailleur et désirez immigrer pour l’Allemagne, SFZ est là
                    pour vous.
                  </p>
                </div>
                <div className="about__btn">
                  <AboutLink
                    className="about__btn--link btn btn-primary"
                    to="about-us#aboutCompagny"
                    role="button"
                  >
                    En sovoir plus &raquo;
                  </AboutLink>
                </div>
              </div>{' '}
              {/* -- /.03 -- */}
              <div id="home-about-3" className="home--about-item">
                <div className="about__img rounded-circle text-center row mx-auto">
                  <img
                    src={TargetImg}
                    className="about__img--img img-fluid m-auto"
                    alt=""
                  />
                </div>
                <div className="about__body">
                  <h2 className="about__body--title">Notre mission</h2>
                  <p className="about__body--text">
                    Nous n'avons qu'une et une seule mission : permettre à
                    toutes les personnes qui nous contactent de réaliser leurs
                    rêves d'immigrer en Allemagne, quelqu'en soit leur
                    situation, et ceux par la bonne voie.
                  </p>
                </div>
                <div className="about__btn">
                  <AboutLink
                    className="about__btn--link btn btn-primary"
                    to="about-us#aboutCompagny"
                    role="button"
                  >
                    En sovoir plus &raquo;
                  </AboutLink>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  -- 
        -----------------------------------------
        PRESENTATION SECTION
        -----------------------------------------
      -- */}
        <MainSection id="presentation">
          <SectionHeaderOne
            id="presentationHeader"
            title="Pourquoi l'Allemagne ?"
            description={
              <>
                Choisir l'Allemagne comme destination pour vos études, votre
                formation ou carrière professionnelle offre de nombreuses
                opportunités et avantages. <br />
                <br />
                Voici quelques raisons convaincantes pour lesquelles vous
                devriez envisager l'Allemagne :
              </>
            }
          />

          <SectionBodyOne id="presentationBody">
            {featuresData ? (
              featuresData.map(
                (
                  { id, order, title, subTitle, description, src, link },
                  index,
                  features
                ) => (
                  <FeatureItemOne
                    key={id}
                    id={id}
                    index={index}
                    order={order}
                    title={title}
                    dataLenght={features.length}
                    subTitle={subTitle}
                    description={description}
                    src={src}
                    link={link}
                  />
                )
              )
            ) : (
              <ComingSoon />
            )}
          </SectionBodyOne>

          <SectionFooterOne
            id="presentationFooter"
            classList="why-german__bottom"
            description={
              <>
                L'Allemagne peut être une décision très enrichissante et
                prometteuse, vous offrant de multiples opportunités pour votre
                avenir personnel et professionnel.
              </>
            }
            link={{ path: 'contacts', text: 'Commencez dès maintenant' }}
          />
        </MainSection>

        {/*  -- 
      -----------------------------------------
      COURSES SECTION
      -----------------------------------------
      -- */}
        <MainSection id="courses">
          <SectionHeaderOne
            id="coursesHeader"
            title="Nos cours et formations professionnelles"
            description={
              <>
                En choisissant nos cours et formations professionnelles en
                langues, vous optez pour une approche complète et axée sur les
                résultats pour maîtriser une nouvelle langue. <br />
                <br />
                Quels que soient vos objectifs, nous sommes là pour vous
                accompagner tout au long de votre parcours linguistique et vous
                aider à réussir.
              </>
            }
          />

          <SectionBodyOne id="coursesBody">
            <div
              className="course justify-content-center justify-content-lg-between"
              id="homeCourses"
            >
              {coursesInformations ? (
                coursesInformations.slice(0, 3).map((detail) => {
                  return (
                    <div
                      key={`ac${detail.id}`}
                      className="card__container my-2 col-md-6 col-lg-4 col-12"
                    >
                      <MainCard
                        title={detail.title}
                        text={detail.description}
                        link={detail.link}
                      />
                    </div>
                  )
                })
              ) : (
                <ComingSoon />
              )}
            </div>
            <SectionFooterLink
              id="coursesFooterLink"
              link={{ path: 'courses', text: 'En savoir plus' }}
            />
          </SectionBodyOne>
        </MainSection>

        {/*  -- 
      -----------------------------------------
      TRAVEL SECTION
      -----------------------------------------
      -- */}
        <MainSection id="travel">
          <SectionHeaderOne
            id="travelHeader"
            title="Opportunités de voyage pour l'Allemagne"
            description={
              <>
                Chez <strong className="site-branding site-name">SFZ</strong>,
                nous croyons en l'ouverture de portes vers de nouvelles
                expériences, en élargissant les horizons et en concrétisant les
                rêves. C'est pourquoi nous sommes ravis de vous présenter nos
                opportunités de voyage uniques : <br />
                <br />
                <strong>
                  des bourses d'études, de travail et de formations en Allemagne
                </strong>
                .
              </>
            }
          />

          <SectionBodyOne id="travelBody">
            <div className="travel">
              <div className="travel__cards">
                {travelOpportunityDetails ? (
                  travelOpportunityDetails.slice(0, 3).map((detail) => {
                    return (
                      <div
                        key={`ac${detail.id}`}
                        className="card__container my-2 col-md-6 col-lg-4 col-12"
                      >
                        <MainCard
                          title={detail.title}
                          text={detail.description}
                          link={detail.link}
                        />
                      </div>
                    )
                  })
                ) : (
                  <ComingSoon />
                )}
              </div>
            </div>
          </SectionBodyOne>

          <SectionFooterOne
            id="travelFooter"
            bgType={'secondary'}
            link={{ path: 'about-us', text: 'En savoir plus' }}
            description={
              <>
                Immigrez en Allemagne grâce aux opportunités que vous offre
                Sprachfluss Zentrum ; obtenez un contrat bien rémunéré !
              </>
            }
            classList="travel__bottom"
            pClassList="travel__bottom--text"
            linkParentClassList="travel__bottom--link"
          />
        </MainSection>

        <FooterComponent />
      </ScrollToTop>
    </>
  )
}

export default Home
