import LogoWhite from '../../assets/img/sfzLogo-white.png'
import SearchIcon from '../../assets/icons/search-b.svg'
import {
  HomeLogoBox,
  HomeLogoImg,
  HomeNavBox,
  HomeSearchIconImg,
  HomeTopBarContainer,
} from './style'
import { Navbar as ReactNavbar, Nav } from 'react-bootstrap'
import { StyledLink } from '../../utils/style/Atoms'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import appColors from '../../utils/style/appColors'
import { NavLinks } from '../../data/NavLinks'

function HomeNavbar() {
  const LOGODEFAULTSIZE = 45
  const [logoSize, setLogoSize] = useState(LOGODEFAULTSIZE)
  const [shadow, setShadow] = useState({})
  const [isExpanded, setIsExpanded] = useState(false)

  const activeNavLink = {
    color: appColors.orange,
    borderBottom: '3px solid',
  }

  useEffect(() => {
    const topbarScrollDownStyle = {
      backgroundColor: `#191b24`,
      boxShadow: `#221d37 0px 2px 0px`,
    }
    function scrollToTop() {
      if (window.scrollY > 50) {
        setLogoSize(30)
        setShadow(topbarScrollDownStyle)
        // console.log('onscroll is Expanded ---> ', isExpanded)
      } else {
        if (isExpanded) setShadow(topbarScrollDownStyle)
        else setShadow({})
        setLogoSize(LOGODEFAULTSIZE)
      }
    }

    // scrollToTop()
    document.addEventListener('scroll', () => scrollToTop(), false)

    if (!isExpanded) document.removeEventListener('scroll', scrollToTop)
    return () => document.removeEventListener('scroll', scrollToTop)
  }, [isExpanded, logoSize, shadow])

  const handleShow = (e) => {
    if (window.scrollY <= 0)
      if (!isExpanded) {
        setShadow({
          backgroundColor: `#191b24`,
          boxShadow: `#221d37 0px 2px 0px`,
        })
        setIsExpanded(true)
      }
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <ReactNavbar
        id="topbar"
        fixed="top"
        expand="lg"
        className="topbar"
        variant="dark"
        style={{ ...shadow }}
      >
        <HomeTopBarContainer className="" id="topbar--container">
          <HomeLogoBox id="logoBox" className="">
            <StyledLink to="/" className="navbar-brand home-link" type="home">
              <HomeLogoImg
                src={LogoWhite}
                id="logo"
                size={logoSize}
                alt="Sprachfluss Zentrum"
                className="d-inline-block align-text-top m-auto"
              />
              {/* <strong className="m-auto logo-text d-none d-sm-inline d-md-none d-lg-inline">
                SPRACHFLUSS ZENTRUM
              </strong> */}
            </StyledLink>
          </HomeLogoBox>

          {/* MENU BUTTON */}
          <ReactNavbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={(e) => handleShow(e)}
          />

          {/* END MENU BUTTON */}

          {/* NAV ELEMENT */}
          <HomeNavBox
            className="justify-content-lg-end"
            id="navbarSupportedContent"
          >
            <Nav className="">
              {NavLinks.map(
                (
                  { path, text, itemClassName, linkClassName, disabled },
                  index
                ) => (
                  <Nav.Item className={itemClassName} key={`${path}${index}`}>
                    <Nav.Link
                      as={NavLink}
                      type="navbar"
                      to={path}
                      disabled={disabled}
                      className={`home-nav--link${
                        linkClassName ? ' ' + linkClassName : ''
                      }`}
                      style={({ isActive }) =>
                        isActive ? activeNavLink : undefined
                      }
                    >
                      {text}
                    </Nav.Link>
                  </Nav.Item>
                )
              )}
              {/* <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Accueil
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/services"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Services
                </Nav.Link>
                <Nav.Item className="">
                  <Nav.Link
                    as={NavLink}
                    type="navbar"
                    to="/courses"
                    className="nav--link"
                    style={({ isActive }) =>
                      isActive ? activeNavLink : undefined
                    }
                  >
                    Courses
                  </Nav.Link>
                </Nav.Item>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/gallery"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Galeries
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/about-us"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  A propos
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  as={NavLink}
                  type="navbar"
                  to="/contacts"
                  id="contacts"
                  className="nav--link"
                  style={({ isActive }) =>
                    isActive ? activeNavLink : undefined
                  }
                >
                  Contacts
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
            {/* NAVBAR ELEMENT */}

            {/* SEARCHBAR ELEMENT */}
            <button id="search" className="btn">
              <HomeSearchIconImg
                src={SearchIcon}
                className="m-auto svgInject"
                alt="search icon"
                id="searchIcon"
              />
            </button>
            <div className="" id="searchbar-div"></div>
          </HomeNavBox>
          {/* NAV ELEMENT */}
        </HomeTopBarContainer>
      </ReactNavbar>
      <div className="" id="cloned--topbar"></div>
    </>
  )
}

export default HomeNavbar
