import React from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'

const SelectsField = ({
  name,
  label,
  register,
  children,
  interestOption,
  isReset,
}) => {
  return (
    <FloatingLabel controlId={name} label={label}>
      {interestOption && !isReset ? (
        <Form.Select
          className=""
          value={interestOption}
          name={name}
          placeholder={label}
          disabled
          {...register}
        >
          {children}
        </Form.Select>
      ) : interestOption && isReset ? (
        <Form.Select
          className="de"
          value={interestOption}
          defaultValue={interestOption}
          name={name}
          placeholder={label}
          {...register}
        >
          {children}
        </Form.Select>
      ) : (
        <Form.Select className="" name={name} placeholder={label} {...register}>
          {children}
        </Form.Select>
      )}
    </FloatingLabel>
  )
}

export default SelectsField
