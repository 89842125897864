import {
  Facebook,
  Twitter,
  Whatsapp,
  Instagram,
  GeoFill,
  PhoneVibrateFill,
} from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

function FooterComponent() {
  const date = new Date()
  const currentYear = date.getFullYear()

  return (
    <footer className="footer container-fluid">

      <div className="row row-cols-lg-3 footer__items py-5">
        <div
          className="footer__item mb-lg-0 footer--about"
          id="footerAbout"
        >
          <div className="footer__title">
            <h5 className="footer__title--text">Sprafluss Zentrum</h5>
            <div className="footer__title--underliner"></div>
          </div>
          <p className="footer--description me-lg-5">
            Sprachfluss Zentrum a été pensé en vue d'aider toute personne
            désirant poursuivre une belle carrière en Allemagne. Nous offrons
            non seulement des formations pratiques et de qualitées, mais aussi
            des opportunités de voyages.
          </p>
          <div className="social">
            <a
              href="http://facebook.com/sprachflusszentrum"
              className="social__link"
              target="blank"
            >
              <Facebook
                color="#4169e1"
                size="2em"
                className="icon social__icon facebook"
                id="facebook"
              />
            </a>
            <a
              href="http://instagram.com/sprachflusszentrum"
              className="social__link"
              target="blank"
            >
              <Instagram
                color="#cb0164"
                size="2em"
                className="icon social__icon instagram"
                id="instagram"
              />
            </a>
            <a
              href="http://twitter.com/sprachflusszentrum"
              className="social__link"
              target="blank"
            >
              <Twitter
                color="#1c9ceb"
                size="2em"
                className="icon social__icon twitter"
                id="twitter"
              />
            </a>
            <a
              href="https://wa.me/237677817649?text=je suis intéressé(e) par vos formations sur le site https://sprachfluss-zentrum.com. Pouvez-vous m'en dire plus ?"
              className="social__link"
              target="blank"
            >
              <Whatsapp
                color="#25d366"
                size="2em"
                className="icon social__icon whatsapp"
                id="whatsapp"
              />
            </a>
          </div>
        </div>

        <div
          className="footer__item mb-lg-0 footer--addresses"
          id="footerAddresses"
        >
          <div className="footer__title">
            <h5 className="footer__title--text">Nos adresses</h5>
            <div className="footer__title--underliner"></div>
          </div>
          <div className="addresses" id="douala">
            <h6 className="addresses__title">Douala</h6>
            <div className="addresses__body">
              <div className="address location">
                <GeoFill className="address--icon location--icon" />
                <span className="address--text location--text">
                  Carrefour Elf, immeuble Beteumen, 3e Niveau
                </span>
              </div>
              <div className="address contact">
                <PhoneVibrateFill className="address--icon contact--icon" />
                <span className="address--text contact--text">
                  (00237) 6 77 81 76 49 | 6 55 13 77 28 | 6 77 22 34 43
                </span>
              </div>
            </div>
          </div>

          <div className="addresses" id="yaoundé">
            <h6 className="addresses__title">Yaoundé</h6>
            <div className="addresses__body">
              <div className="address location">
                <GeoFill className="address--icon location--icon" />
                <span className="address--text location--text">
                  Quartier Essos, Avenue Germaine, immeuble LA GRACE DE DIEU,
                  3e Niveau
                </span>
              </div>
              <div className="address contact">
                <PhoneVibrateFill className="address--icon contact--icon" />
                <span className="address--text contact--text">
                  (00237) 6 94 41 36 24 | 6 53 35 23 15
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="footer__item mb-0 footer--contact"
          id="footerContact"
        >
          <div className="footer__title">
            <h5 className="footer__title--text">Nous contacter</h5>
            <div className="footer__title--underliner"></div>
          </div>
          <p>Laissez-nous un message !</p>
          <div className="footer__item--body w-100">
            <form className="footer__form">
              <div className="form__group form-floating">
                <input
                  id="email"
                  type="email"
                  className="form-control footer__form--control form__group--input"
                  placeholder="Adresse email"
                />
                <label htmlFor="email" className="form__group--label">
                  Adresse email :
                </label>
              </div>
              <div className="form__group form-floating">
                <textarea
                  id="message"
                  className="form-control footer__form--control form__group--textarea"
                  placeholder="Votre message"
                />
                <label htmlFor="message" className="form__group--label">
                  Votre message :
                </label>
              </div>
              <div className="form__group form__button link__btn">
                <Link
                  className="link__btn--primary btn btn-primary"
                  to="team"
                  role="button"
                  type="button"
                >
                  Envoyer
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <hr className="hr--divider" /> */}

      <div className="copyright--div d-flex align-items-center justify-content-between py-4 mt-5">
        <p className="m-auto">
          &copy;{currentYear} Sprachfluss Zentrum, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default FooterComponent
