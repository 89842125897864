import * as React from 'react'

const SvgFamilyIcon = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="family"
    className="svg-inline--fa fa-family fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    id="family-icon_svg__family-icon"
    viewBox="0 0 100 106.71"
    {...props}
  >
    <g>
      <path
        fill="currentColor"
        d="M88.94 89.15c-.07 2.31.17 5.17-.1 8-.54 5.56-4.26 9.18-9.84 9.42a42.91 42.91 0 0 1-8.25-.16 10.3 10.3 0 0 1-4.73-2 1.68 1.68 0 0 1-.73-1.85 61.18 61.18 0 0 0 .22-10.13c0-2.51.24-4.59 2.19-6.62 3.35-3.5 4.42-8.09 4.55-12.86a29.33 29.33 0 0 0-.92-9.42 23 23 0 0 0-10.56-13.6c-.84-.49-1-.72-.24-1.48a16.14 16.14 0 0 0 5-12.36 1.38 1.38 0 0 1 .88-1.45 12.86 12.86 0 0 1 5.71-1.36h7.41c5.14 0 10.66 4.08 12.24 9.84 2.56 9.3 5.29 18.54 7.9 27.82 1.4 4.95-1.69 8.93-6.85 8.94h-3.07c-.67 0-.82.2-.81.83.02 2.68 0 5.29 0 8.44ZM37.92 49.39a23.72 23.72 0 0 0-9.77 9.85 23.17 23.17 0 0 0-2.66 10.65 28.18 28.18 0 0 0 1.35 10.62 19.46 19.46 0 0 0 4.66 7.09 2.27 2.27 0 0 1 .71 1.76v11.63a6.06 6.06 0 0 0 .06.95c.31 2 .2 2.19-1.54 3.25a9.36 9.36 0 0 1-5 1.41 65.06 65.06 0 0 1-8.14-.15 9.74 9.74 0 0 1-8.65-8.76 21.67 21.67 0 0 1-.13-2.42V73.38a1.12 1.12 0 0 0-.76-1.26 13 13 0 0 1-8-13.56q.44-4.78.76-9.59A16.42 16.42 0 0 1 16.3 33.44a105.13 105.13 0 0 1 12.17 0 12.83 12.83 0 0 1 3.49.81c.28.09.39.17.36.49a16.54 16.54 0 0 0 5.48 14.44s.04.05.12.21Z"
      />
      <path
        fill="currentColor"
        d="M38.83 92.68V85.6a1.33 1.33 0 0 0-.65-1.3c-4.23-2.8-5.93-6.88-6-11.8a21.79 21.79 0 0 1 1.35-9.12 15.37 15.37 0 0 1 10-9.18c6.19-1.84 11.89-.78 16.76 3.6a14.89 14.89 0 0 1 4.56 7.71 23.69 23.69 0 0 1 .05 11.29 12.26 12.26 0 0 1-5.1 7.27 2 2 0 0 0-1 1.89c.05 4.45 0 8.89 0 13.33 0 4.78-2.54 7.33-7.3 7.33h-5.72c-4.38 0-7-2.58-7-7 .03-2.28.05-4.61.05-6.94Zm-30-79.52a13.31 13.31 0 1 1 13.14 13.43A13.34 13.34 0 0 1 8.83 13.16Zm80.08.16A13.33 13.33 0 1 1 75.61 0a13.61 13.61 0 0 1 13.3 13.32ZM49.12 46.59c-5.41-.14-10-3.35-10.17-9.8-.14-5.23 3.13-9.77 9.66-10 5.44-.22 9.94 3.29 10.17 9.74.19 5.02-3.15 9.57-9.66 10.06Z"
      />
    </g>
  </svg>
)
export default SvgFamilyIcon
