import { Navbar, Container } from 'react-bootstrap'
import styled from 'styled-components'
import appColors from '../../utils/style/appColors'

export const TopBar = styled(Navbar)`
  /* height: 85px; */
  top: 0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  /* border-bottom: 1px solid ${appColors.lineColor}; */
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  ${(props) => props.shadow};
`

export const TopBarContainer = styled(Container)`
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
`

export const NavBox = styled(Navbar.Collapse)`
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
`

export const NavBarItem = styled.div`
  height: 100%;
`

export const LogoBox = styled.div`
  display: flex;
  height: 100%;
  padding: 8px 15px;
  margin-left: 25px;
  margin-right: 15px;
`

export const LogoImg = styled.img`
  height: ${(props) => props.size || `45`}px;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
`

export const SearchIconImg = styled.img`
  padding: 2px;
  height: 30px;
`
