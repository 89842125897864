import { createBrowserRouter } from 'react-router-dom'

import App from '../pages/App'
import Home from '../pages/Home'
import ErrorPage from '../pages/ErrorPage'
import Courses from '../pages/Courses'
import Services from '../pages/Services'
import Contacts from '../pages/Contacts'
import AboutUs from '../pages/Aboutus'
import CoursesDetails from '../pages/Courses/coursesDetails'
import StudyTripCourses from '../pages/Courses/StudyTripCourses'
import Exampreparation from '../pages/Courses/Exampreparation'
import KidsAndYouthCourses from '../pages/Courses/KidsAndYouthCourses'
import TeamDetails from '../components/team/TeamDetails'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/courses',
    children: [
      { index: true, element: <Courses /> },
      {
        path: 'study-trips',
        element: <StudyTripCourses />,
        // loader: ({ params }) => console.log(params.teamId), // "hotspur",
      },
      {
        path: 'exam-preparation',
        element: <Exampreparation />,
        // loader: ({ params }) => console.log(params.teamId), // "hotspur",
      },
      {
        path: 'kids-youth',
        element: <KidsAndYouthCourses />,
        // loader: ({ params }) => console.log(params.teamId), // "hotspur",
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      // { index: true, element: <Home /> },
      // { path: 'courses', element: <Courses /> },
      {
        path: 'offers',
        children: [
          { index: true, element: <Services /> },
          {
            path: ':option',
            element: <CoursesDetails />,
            // loader: ({ params }) => console.log(params.teamId), // "hotspur",
          },
        ],
      },
      {
        path: 'contacts',
        element: <Contacts />,
      },
      { path: 'contacts-:interestOption', element: <Contacts /> },
      {
        path: 'about-us',
        element: <AboutUs />,
      },
      {
        path: 'team/:memberId', // :id sera remplacé par l'ID du membre
        element: <TeamDetails />,
        exact: true,
      },
    ],
  },
])

export default router
