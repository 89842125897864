import { Card } from 'react-bootstrap'
import { CheckLg } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import PropTypes, { string, number, element, bool } from 'prop-types'

/**
 *
 * @param {Object} props destructured props of the component
 * @returns {JSX} JXElement
 */
function SimpleCard({ title, list, linkId }) {
  return (
    <div className="main-card mx-2">
      <Card.Title as={'h3'} className="simple-card__title h3">
        {title}
      </Card.Title>
      <Card.Body className="simple-card__body">
        <ul className="simple-card__ul">
          {list.map((item, index) => {
            return (
              <li
                className="simple-card__li ul__item"
                key={`${linkId}${index}`}
              >
                <CheckLg
                  className="icon"
                  id="checkLg"
                  color="#7cd724"
                  size={'25px'}
                />
                <span className="ul__item--text">{item}</span>
              </li>
            )
          })}
        </ul>
      </Card.Body>
      <Card.Footer>
        <Card.Link
          as={Link}
          className="simple-card__link text-uppercase"
          to={`${linkId}`}
        >
          PLUS D'INFORMATIONS
        </Card.Link>
      </Card.Footer>
    </div>
  )
}

SimpleCard.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  linkId: PropTypes.oneOfType([string, number]).isRequired,
}

/**
 *
 * @param {Object} props destructured props of the component
 * @returns {JSX} JXElement
 */
export function MainCard({ title, text, link }) {
  return (
    <div className="main-card mx-2">
      <h3 className="main-card__title h3">{title}</h3>
      <div className="main-card__body">
        <p className="main-card__description text-center">{text}</p>
      </div>
      {link && (
        <div>
          <Link className="main-card__link" to={`about-us/${link}`}>
            En savoir plus
          </Link>
        </div>
      )}
    </div>
  )
}

MainCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
}

export function CardOne({ picture, firstname, surename, link, type, role }) {
  return (
    <div className="card-one team--card">
      <div className="card-one__header member--picture">
        <img src={picture} className="img-cover" alt="" />
      </div>
      <div className="card-one__body team--card__body">
        <div className="card-one__body--container">
          <h3 className="card-one__body--title member-name">
            <span className="firstname">{firstname}</span>{' '}
            <span className="surename">{surename}</span>
          </h3>
          <p className="card-one__body--description member-function mb--3">
            {role}
          </p>
        </div>
        {link && (
          <div className="card-one__footer mb--1">
            <Link
              to={link.path}
              className={`link__btn${
                type ? '--' + type : ''
              } card-one__footer--link`}
            >
              {link.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

CardOne.propTypes = {
  picture: PropTypes.string.isRequired,
  surename: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  link: PropTypes.shape({
    path: string,
    text: string,
  }),
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
}

export function TeamCard({
  picture,
  firstname,
  surename,
  skills,
  experience,
  link,
  type,
  role,
}) {
  return (
    <div className="team-card">
      <div className="team-card__header member-picture">
        <img src={picture} className="img-cover" alt={firstname} />
      </div>
      <div className="team-card__body team--card__body">
        <div className="team-card__body--container">
          <div className="team-card__header member-picture mobile">
            <img src={picture} className="img-cover" alt={firstname} />
          </div>
          <div className="member-name px--4">
            <h3 className="team-card__body--title">
              <span className="firstname">{firstname}</span>{' '}
              <span className="surename">{surename.toUpperCase()}</span>
            </h3>
            <p className="team-card__body--description member-function mb--3">
              {role}
            </p>
          </div>
          <div className="member-info px--4">
            <p>
              <strong>Compétences :</strong> {skills.join(', ')}
            </p>
            <p>
              <strong>Expérience :</strong> {experience}
            </p>
          </div>
        </div>
        {link && (
          <div className="team-card__footer text-center text-md-start px--4 mb--1">
            <Link
              to={link.path}
              className={`link__btn${
                type ? '--' + type : ''
              } team-card__footer--link${link.disabled ? ' btn disabled' : ''}`}
            >
              {link.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

TeamCard.propTypes = {
  picture: PropTypes.string.isRequired,
  surename: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  experience: PropTypes.string.isRequired,
  link: PropTypes.shape({
    path: string,
    text: string,
    disabled: bool,
  }),
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
}

export function ListItemOne({
  id,
  iconId,
  image,
  icon,
  description,
  classList,
  headerClassList,
  bodyClassList,
  footerClassList,
  spanImageClassList,
  hasLink,
  children,
}) {
  // console.log(typeof description === 'string')

  return (
    <>
      <li className={`list-item${classList ? ' ' + classList : ''}`} id={id}>
        <div
          className={`list-item__header${
            headerClassList ? ' ' + headerClassList : ''
          }${icon ? ' py--3' : ''}`}
        >
          <span
            className={`list-item__header--icon${
              spanImageClassList ? ' ' + spanImageClassList : ''
            }${icon ? ' icon' : ''}`}
            id={iconId}
          >
            {image}
          </span>
        </div>
        <div
          className={`list-item__body${
            bodyClassList ? ' ' + bodyClassList : ''
          }${!hasLink ? ' mb--4' : ''}`}
        >
          <p className="list-item__body--text">
            {typeof description === 'string' ? (
              <span dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              <span>{description}</span>
            )}
          </p>
        </div>
        {hasLink && (
          <div
            className={`list-item__footer${
              footerClassList ? ' ' + footerClassList : ''
            }`}
          >
            {children}
          </div>
        )}
      </li>
    </>
  )
}

ListItemOne.defaultProps = {
  icon: false,
  hasLink: true,
}

ListItemOne.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([string, element]).isRequired,
  iconId: PropTypes.string,
  icon: PropTypes.bool,
  description: PropTypes.oneOfType([string, element]).isRequired,
  classList: PropTypes.string,
  headerClassList: PropTypes.string,
  bodyClassList: PropTypes.string,
  footerClassList: PropTypes.string,
  hasLink: PropTypes.bool,
  spanImageClassList: PropTypes.string,
}

export function ListItemTwo({
  id,
  iconId,
  image,
  description,
  classList,
  spanImageClassList,
  children,
}) {
  return (
    <>
      <li className={`list__item${classList ? ' ' + classList : ''}`} id={id}>
        <div className={`list__item--icon`}>
          <span
            className={`icon${
              spanImageClassList ? ' ' + spanImageClassList : ''
            }`}
            id={iconId}
          >
            {image}
          </span>
        </div>
        <div className="list__item--text">
          <p className="item--text">
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </p>
          {children}
        </div>
      </li>
    </>
  )
}

ListItemTwo.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([string, element]).isRequired,
  iconId: PropTypes.string,
  description: PropTypes.oneOfType([string, element]).isRequired,
  classList: PropTypes.string,
  spanImageClassList: PropTypes.string,
}

export default SimpleCard
