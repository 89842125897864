const appColors = {
  primary: '#4d28e8',
  secondary: '#6c757d',
  backgroundLight: '#f8f9fa',
  backgroundDark: '#260938',
  default: '#7d8083',
  textLight: '#cbbdd5',
  sfzorangeLight: '#f8e9d4',
  lightGray: '#a6a6a6',
  lightBlue: '#e4f4ff',
  orange: '#fc9803',
  gray: '#7b818f',
  darkGray: '#4a505e',
  textColor: '#260938',
  textDark: '#260938',
  danger: '#ff522d',
  info: '#87b6ff',
  warning: '#ffcb57',
  lineColor: '#f4f4f4',
}

export default appColors
