import React from 'react'
import PropTypes from 'prop-types'

function MainSectionBody({ id, containerClassList, classList, children }) {
  return (
    <>
      {/*
        -----------------------------------------
        SECTION BODY ONE
        -----------------------------------------
      */}
      <div
        className={`section__body${classList ? ' ' + classList : ''}`}
        id={id}
      >
        <div
          className={`section__container${
            containerClassList ? ' ' + containerClassList : ''
          }`}
        >
          {/*  -- START THE FEATURETTES -- */}

          {children}
        </div>
      </div>
    </>
  )
}

MainSectionBody.propTypes = {
  id: PropTypes.string.isRequired,
  classList: PropTypes.string,
  containerClassList: PropTypes.string,
}

export default MainSectionBody
