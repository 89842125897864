import DefaultMaleAvatar from '../../assets/img/man-default-profile-2.png'
import DefaultFemaleAvatar from '../../assets/img/woman-default-profile-2.png'

/**
 * STOP AND PLAY SHAKING BUTTON
 * @returns Object
 */
export const ShakeButton = () =>
  setInterval(() => {
    if (document.querySelector('#HeaderScrollBtn'))
      document.querySelector('#HeaderScrollBtn').classList.add('arret')

    let timeOut = setTimeout(() => {
      if (document.querySelector('#HeaderScrollBtn'))
        document.querySelector('#HeaderScrollBtn').classList.remove('arret')
    }, 3000)

    setTimeout(() => clearTimeout(timeOut), 12000)
  }, 6000)

/**
 *
 * @returns Random color
 */
export const randomColor = () =>
  '#' + Math.floor(Math.random() * 16777215).toString(16)

/**
 *
 * @param {Object | String} elts
 */
export function randomBgColor(elts) {
  let nodes
  if (typeof elts === 'string') nodes = document.querySelectorAll(elts)
  else nodes = elts

  // console.log(typeof nodes)

  nodes.forEach((icon) => {
    icon.style.backgroundColor = randomColor()
  })
}

/**
 * RETURNS AVATAR FOR MAN OR WOMAN
 * @param {String} gender
 * @returns path to default avatar
 */
export const getDefaultAvatar = (gender) => {
  if (gender !== 'M' && gender !== 'F') {
    console.error('Invalid gender value:', gender)
    return DefaultMaleAvatar
  }
  return gender === 'M' ? DefaultMaleAvatar : DefaultFemaleAvatar
}
