import React from 'react'

import DefaultMaleAvatar from '../../assets/img/man-default-profile-2.png'
import DefaultFemaleAvatar from '../../assets/img/woman-default-profile-2.png'
import { teamData } from '../../data/abouts/teamData'
import { TeamCard } from '../Cards'
// import { Button, Card } from 'antd'
// import { Link } from 'react-router-dom'

const Team = () => {
  const activeMembers = teamData.filter(({ disabled }) => !disabled)
  const getDefaultAvatar = (gender) => {
    return gender === 'M' ? DefaultMaleAvatar : DefaultFemaleAvatar
  }

  return (
    <>
      <div className="team-container mx-5 mx-lg-0 align-items-stretch">
        {activeMembers.map((member, index) => (
          <TeamCard
            key={member.id}
            picture={member.picture || getDefaultAvatar(member.gender)}
            surename={member.surename}
            firstname={member.firstname}
            role={member.role}
            skills={member.skills}
            experience={member.experience}
            link={{
              path: `/team/${index + 1}`,
              text: 'Plus de détails',
              disabled: member.disabled,
            }}
          />
        ))}
      </div>

      {/* <div className="team-container mx-5 mx-lg-0 align-items-stretch">
        {activeMembers.map((member, index) => (
          <Card
            key={member.id}
            title={`${member.firstname} ${member.surename.toUpperCase()}`}
            extra={
              <Button
                as={Link}
                to={`/team/${index + 1}`}
                type="primary"
                disabled={member.disabled}
              >
                Plus de détails
              </Button>
            }
            style={{ width: 300, margin: '20px' }}
          >
            <img
              src={member.picture || getDefaultAvatar(member.gender)}
              alt={`${member.firstname} ${member.surename}`}
              style={{ width: '100%', height: 'auto' }}
            />
            <p>
              <strong>Rôle:</strong> {member.role}
            </p>
            <p>
              <strong>Expérience:</strong> {member.experience}
            </p>
          </Card>
        ))}
      </div> */}
    </>
  )
}

export default Team
