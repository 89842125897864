import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

function PopUpMessage({
  icon,
  closeIcon,
  popUpMessageClassName,
  messageClassName,
  messageText,
}) {
  return (
    <div className={popUpMessageClassName}>
      {icon}
      <span className={messageClassName}>{messageText}</span>
      {closeIcon}
    </div>
  )
}

export default PopUpMessage

PopUpMessage.propTypes = {
  icon: PropTypes.object.isRequired,
  closeIcon: PropTypes.elementType,
  popUpMessageClassName: PropTypes.string.isRequired,
  messageClassName: PropTypes.string.isRequired,
  messageText: PropTypes.string.isRequired,
}

PopUpMessage.defaultProps = {
  icon: (
    <FontAwesomeIcon
      className="popup__message--icon"
      icon={regular('circle-check')}
      size="2x"
    />
  ),
  popUpMessageClassName: 'popup__message',
  messageClassName: 'popup__message--text',
  messageText: 'Message envoyé !!!',
}
