import * as React from 'react'
const SvgGraduationCap = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="graduation-cap"
    className="svg-inline--fa fa-graduation-cap fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 70.07"
    {...props}
  >
    <path
      fill="currentColor"
      d="m11.88 27.05-4.27-1.56c-1.64-.6-3.29-1.19-4.92-1.8a3.85 3.85 0 0 1-1.85-6.06 4.14 4.14 0 0 1 2-1.33L33.45 5.38c4.64-1.65 9.27-3.32 13.91-4.94a7.84 7.84 0 0 1 5.32 0l44.18 15.8a4.33 4.33 0 0 1 2.76 2.18 3.77 3.77 0 0 1-2.4 5.29q-7.39 2.66-14.8 5.29-14.88 5.3-29.77 10.6a7.84 7.84 0 0 1-5.32 0Q32.42 34.31 17.54 29a.87.87 0 0 0-1.18.33 17.67 17.67 0 0 0-3.31 6.34.7.7 0 0 0 .24.82 4.64 4.64 0 0 1 .19 6.87 1.08 1.08 0 0 0-.31 1.1c.87 5.08 1.71 10.17 2.56 15.25q.56 3.38 1.13 6.75a6.54 6.54 0 0 1 .08.88 2.47 2.47 0 0 1-2.69 2.72H5.67c-1.95 0-3-1.32-2.65-3.36.27-1.75.58-3.48.87-5.23q1.23-7.33 2.45-14.67c.14-.8.25-1.61.42-2.4a1 1 0 0 0-.32-1 4.66 4.66 0 0 1-.23-6.5 10.79 10.79 0 0 1 1.46-1.11c.19-.15.44-.32.49-.53a21 21 0 0 1 3.72-8.21Zm2.83-7a6.26 6.26 0 0 0 .58.25c1.25.45 2.5.91 3.76 1.33a1.62 1.62 0 0 0 .9 0l18.65-5.7c3.43-1 6.86-2.1 10.29-3.15 1.86-.56 3.34.32 3.51 2.07a2.49 2.49 0 0 1-2 2.63l-22.1 6.81-.77.25.57.25q10.44 3.75 20.88 7.53a3.14 3.14 0 0 0 2.2 0l33.56-12c.17-.06.34-.15.63-.27l-.73-.28q-16.78-6-33.57-12a3.44 3.44 0 0 0-2.43 0l-33 11.87Z"
    />
    <path
      fill="currentColor"
      d="m22.2 36 .88.31c1.93.71 3.86 1.44 5.8 2.13.34.12.46.27.42.64q-.82 7.44-1.63 14.9a10.1 10.1 0 0 1-.15 1.36 2 2 0 0 0 1.05 2.19 26.29 26.29 0 0 0 6.46 3 41 41 0 0 0 10.24 1.76 73.85 73.85 0 0 0 7.73.12c4.54-.23 9.08-.68 13.35-2.38a52.62 52.62 0 0 0 5.11-2.52 1.87 1.87 0 0 0 1-2.07c-.42-3.53-.78-7.06-1.16-10.59-.22-2-.43-4.1-.66-6.21L77.82 36c.12 1 .23 1.86.33 2.76.6 5.4 1.24 10.8 1.77 16.21a8.94 8.94 0 0 1-2.15 6.51 16.48 16.48 0 0 1-7.28 5 51.64 51.64 0 0 1-15.28 3.36A69.9 69.9 0 0 1 43 69.69a47.26 47.26 0 0 1-15.14-3.91A14.36 14.36 0 0 1 21.81 61a9.9 9.9 0 0 1-1.63-7.18c.54-4.09.94-8.2 1.41-12.3Z"
    />
  </svg>
)
export default SvgGraduationCap
