export const coursesInformations = [
  {
    id: 1,
    title: 'Expertise Pédagogique Avérée',
    description:
      "Nos cours sont dispensés par des enseignants hautement qualifiés et expérimentés dans l'enseignement des langues. Leur expertise garantit un apprentissage efficace et de haute qualité.",
    link: undefined,
  },
  {
    id: 2,
    title: 'Approche Personnalisé',
    description:
      'Nous comprenons que chaque apprenant a des besoins uniques. Nos programmes sont conçus pour répondre spécifiquement à vos objectifs linguistiques, que ce soit pour des raisons professionnelles, académiques ou personnelles.',
  },
  {
    id: 3,
    title: 'Méthodologie Innovante',
    description:
      'Nos méthodes pédagogiques sont modernes et interactives pour rendre un apprentissage amusant et engageant. Nos cours combinent des activités variées pour développer les compétences en compréhension orale, expression écrite et orale et la lecture.',
  },
  {
    id: 4,
    title: 'Flexibilité des Horaires',
    description:
      "Nous comprenons que nos apprenants ont des horaires chargés. Nos cours sont proposés à des horaires flexibles pour s'adapter à vos contraintes de temps.",
  },
  {
    id: 5,
    title: 'Cours pour Tous les Niveaux ',
    description:
      'Que vous soyez débutant ou que vous souhaitiez perfectionner vos compétences existantes, nos cours sont adaptés à tous les niveaux. Nous vous aiderons à progresser à votre rythme.',
  },
  {
    id: 6,
    title: 'Préparation à des Examens Officiels',
    description:
      "Si vous avez besoin de passer des examens de langue officiels pour des raisons telles que l'immigration ou l'admission à une université, nos cours de préparation vous aideront à réussir avec confiance.",
  },
  {
    id: 7,
    title: 'Cours Spécialisés',
    description:
      "Nos cours comprennent des options spécifiques, comme les cours d'allemand pour l'obtention du ZDAF, qui répondent à des besoins particuliers comme l'immigration en Allemagne.",
  },
  {
    id: 8,
    title: 'Ressources Enrichissantes',
    description:
      "En plus des cours, vous aurez accès à une variété de ressources, de matériaux et d'outils en ligne pour renforcer votre apprentissage et votre pratique linguistique en dehors des séances en classe.",
  },
  {
    id: 9,
    title: 'Soutien Continu',
    description:
      'Notre engagement envers votre réussite ne se limite pas à la salle de classe. Nous offrons un suivi continu et des conseils pour vous aider à surmonter les défis et à atteindre vos objectifs.',
  },
  {
    id: 10,
    title: "Communauté d'Apprentissage",
    description:
      "En rejoignant nos cours, vous ferez partie d'une communauté d'apprenants passionnés partageant des objectifs similaires. Les échanges avec vos pairs peuvent enrichir votre expérience d'apprentissage.",
  },
]

export const germanCourses = [
  {
    id: 'german-study-trips',
    title: "Pour voyage d'étude",
    list: [
      'Cours de niveau A1, A2, B1, B2, C2',
      'Commencer avant le Bac',
      'Choix de niveau',
      "Choix du rythme d'apprentissage",
    ],
    path: 'study-trips',
    highlights: [
      {
        id: 1,
        title: 'Flexibilité',
        description:
          "Nos programmes sont conçus pour s'adapter à vos besoins et à vos contraintes de temps. Choisissez la durée qui vous convient, qu'il s'agisse de quelques semaines ou de plusieurs mois.",
      },
      {
        id: 2,
        title: 'Apprentissage Intensif ',
        description:
          'Plongez-vous dans la langue allemande grâce à des cours intensifs dirigés par des instructeurs hautement qualifiés et expérimentés.',
      },
      {
        id: 3,
        title: 'Interaction Culturelle',
        description:
          "Les cours sont complétés par des activités culturelles passionnantes qui vous permettront d'interagir avec les habitants, d'explorer les sites emblématiques et de découvrir la vie quotidienne en Allemagne.",
      },
      {
        id: 4,
        title: 'Immersion Linguistique ',
        description:
          'Nos cours vous offrent une immersion totale dans la langue, ce qui accélérera votre apprentissage pour un meilleur vivre en Allemagne.',
      },
      {
        id: 5,
        title: 'Découverte Culturelle ',
        description:
          "Grâce à nos cours, explorez plus aisément les trésors culturels, historiques et artistiques de l'Allemagne lors de vos visites guidées et des excursions organisées.",
      },
      {
        id: 6,
        title: 'Opportunités de Réseautage ',
        description:
          "Rencontrez d'autres apprenants désirants voyager comme vous et créez des liens précieux tout en partageant votre voyage d'apprentissage.",
      },
    ],
  },
  {
    id: 'german-exam-preparation',
    title: 'Préparation aux examens',
    list: [
      'Au ZDAF (B1), MITTELSTUFE (B2), TESTDAF (C5)',
      'Au BEPC, Au probatoire',
      'Au Baccalauréat',
    ],
    path: 'exam-preparation',
    highlights: [
      {
        id: 'ep-highlight1',
        title: 'Préparation Complète',
        description: "Nos cours vous préparent à tous les aspects de l'examen.",
      },
      {
        id: 'ep-highlight2',
        title: 'Instructeurs Expérimentés',
        description:
          "Bénéficiez des conseils d'experts en préparation aux examens.",
      },
      {
        id: 'ep-highlight3',
        title: "Simulations d'Examen",
        description:
          "Pratiquez dans des conditions similaires à l'examen réel.",
      },
      {
        id: 'ep-highlight4',
        title: 'Stratégies Efficaces',
        description:
          "Apprenez des stratégies pour gérer les différentes sections de l'examen.",
      },
      {
        id: 'ep-highlight5',
        title: 'Feedback Personnalisé',
        description:
          'Recevez un retour personnalisé pour améliorer vos compétences.',
      },
    ],
  },
  {
    id: 'german-kids-youth',
    title: 'Pour enfants et jeunes',
    list: ['de 8 à 16 ans', 'cours de vacances', "adapté selon l'âge"],
    path: 'kids-youth',
    highlights: [
      {
        id: 'ky-highlight1',
        title: 'Approche Interactive',
        description:
          'Les enfants et les jeunes apprennent en s’amusant grâce à des activités interactives.',
      },
      {
        id: 'ky-highlight2',
        title: 'Instructeurs Qualifiés',
        description:
          "Nos enseignants sont expérimentés dans l'enseignement aux jeunes apprenants.",
      },
      {
        id: 'ky-highlight3',
        title: 'Fondations Solides',
        description:
          'Nous établissons des bases linguistiques solides pour un apprentissage à long terme.',
      },
      {
        id: 'ky-highlight4',
        title: 'Éveil Culturel',
        description:
          'Ils découvriront la culture allemande à travers des activités culturelles divertissantes.',
      },
      {
        id: 'ky-highlight5',
        title: 'Épanouissement Personnel',
        description:
          'Nous favorisons le développement des compétences linguistiques et de la confiance en soi chez les jeunes.',
      },
    ],
  },
  {
    id: 'german-refresher-courses',
    title: 'Cours de remise à niveau',
    list: [
      'ZDAF, MITTELSTUFE, TESTDAF',
      'Au BEPC, probatoire et Bacc',
      'Classes intermédiaires',
      'Pendant les vacances',
    ],
    path: 'refresher-courses',
  },
]

export const englishCourses = [
  {
    id: 1,
    title: 'Pour enfants et jeunes',
    list: ['de 6 à 18 ans', 'cours de vacances', "adapté selon l'âge"],
    path: '',
  },
  {
    id: 2,
    title: 'Pour adultes',
    list: [
      'Anglais des affaires',
      'Améliorer ses connaissances',
      'Parler fluemment',
      "Choix du rythme d'apprentissage",
    ],
    path: '',
  },
  {
    id: 3,
    title: 'Préparation aux examens',
    list: ['Au TOEF', 'Au BEPC', 'Au probatoire', 'Au Baccalauréat'],
    path: '',
  },
  {
    id: 4,
    title: 'Cours de remise à niveau',
    list: [
      'Au TOEFL',
      'Au BEPC',
      'Au probatoire',
      'Au Baccalauréat',
      'Classes intermédiaire',
    ],
    path: '',
  },
]

export const computerCourses = [
  // {
  //   id: 1,
  //   title: 'Initiation l\'ordinateur',
  //   list: ['de 6 à 18 ans', 'cours de vacances', "adapté selon l'âge"],
  // },
  // {
  //   id: 2,
  //   title: "Pour adultes",
  //   list: [
  //     'Anglais des affaires',
  //     'Améliorer ses connaissances',
  //     'Parler fluemment',
  //     "Choix du rythme d'apprentissage",
  //   ],
  // },
  // {
  //   id: 3,
  //   title: 'Préparation aux examens',
  //   list: ['Au TOEF', 'Au BEPC', 'Au probatoire', 'Au Baccalauréat'],
  // },
  // {
  //   id: 4,
  //   title: 'Cours de remise à niveau',
  //   list: [
  //     'Au TOEFL',
  //     'Au BEPC',
  //     'Au probatoire',
  //     'Au Baccalauréat',
  //     'Classes intermédiaire',
  //   ],
  // },
]
