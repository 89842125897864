import React from 'react'
import { Outlet } from 'react-router-dom'
import NavbarComponent from '../components/Navbar'
import FooterComponent from '../components/Footers'
import ScrollToTop from '../components/ScrollToTop'

function App() {
  return (
    <>
      <ScrollToTop>
        <NavbarComponent />
        <Outlet />
        <FooterComponent />
      </ScrollToTop>
    </>
  )
}

export default App
