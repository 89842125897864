import HomeCarousel from '../HomeCarousel'

function HomeHeader() {
  return (
    <header id="page__header">
      <HomeCarousel />
    </header>
  )
}

export default HomeHeader
