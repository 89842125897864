import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Paper } from '@mui/material'

import { teamData } from '../../data/abouts/teamData'
import MainHeader from '../Headers/MainHeader'
import ServicesHeaderImg from '../../assets/img/aboutusheaderimg.png'
import MemberDetails from './MemberDetails'
import { getDefaultAvatar } from '../../utils/functions'

const TeamDetails = () => {
  const { memberId } = useParams()
  const member = teamData.find((m) => m.id === Number(memberId))

  const memberPicture = member.picture || getDefaultAvatar(member.gender)

  const handleScrollBottom = () => {
    const scrollHeight =
      document.getElementById('aboutusHeader').offsetHeight - 66

    window.scrollTo({ top: scrollHeight })
  }

  if (!member) {
    return (
      <>
        <MainHeader
          id="aboutusHeader"
          title={"à propos des Membre de l'équipe"}
          description={'Détails du Membre'}
          className="aboutus__header page-header"
          headerContanierClassName={'row-cols-1'}
          headerBodyClassName={'col-md-5'}
          headerImgClassName={'col-md-7'}
          image={{
            src: ServicesHeaderImg,
            alt: 'about us header image',
            className: 'img-cover item-image-img',
          }}
          handleScrollBottom={handleScrollBottom}
        />
        <Container>
          <Paper elevation={3} className="team-details-container">
            <div>Le membre spécifié n'existe pas.</div>
          </Paper>
        </Container>
      </>
    )
  }

  return (
    <>
      <MainHeader
        id="aboutusHeader"
        title={`${member.firstname} ${member.surename.toLowerCase()}`}
        subtitle={'à propos de'}
        description={member.description}
        className="aboutus__header page-header"
        headerContanierClassName={'row-cols-1'}
        headerBodyClassName={'col-md-5'}
        headerImgClassName={'team-header-picture col-md-7'}
        image={{
          src: memberPicture,
          alt: `${member.firstname} ${member.surename.toLowerCase()}`,
          className: 'img-cover team-avatar',
        }}
        handleScrollBottom={handleScrollBottom}
      />

      <MemberDetails member={member} />
    </>
  )
}

export default TeamDetails
