import * as React from 'react'
const SvgSpinnerThird = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="spinner-third"
    className="svg-inline--fa fa-duotone fa-spinner-third fa-3x"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.31 101.57"
    {...props}
  >
    <g data-name="spinnner-third">
      <path
        d="M50.65 0a50.66 50.66 0 1 0 50.66 50.65A50.65 50.65 0 0 0 50.65 0Zm0 89A38.35 38.35 0 1 1 89 50.65 38.35 38.35 0 0 1 50.65 89Z"
        fill="#a2acb8"
      />
      <path
        d="m7.2 60.59.19-.06A6.15 6.15 0 0 1 15 64.15C25.15 90.35 60 99 80.34 75a6.17 6.17 0 0 1 8.57-.84 6.19 6.19 0 0 1 .84 8.83C61 117.11 14.44 99.49 3.28 68.51a6.16 6.16 0 0 1 3.92-7.92Z"
        fill="#193152"
      />
    </g>
  </svg>
)
export default SvgSpinnerThird
