export const travelOpportunityDetails = [
  {
    id: 1,
    title: 'Accès à des Ressources de Haut Niveau',
    description:
      'Profitez de ressources éducatives et professionnelles de première classe pour enrichir votre apprentissage.',
    link: undefined,
  },
  {
    id: 2,
    title: 'Accompagnement Personnalisé',
    description:
      "Notre équipe dédiée vous guidera à chaque étape du processus, de l'inscription aux démarches administratives.",
  },
  {
    id: 3,
    title: 'Expérience Complète',
    description:
      "Nos opportunités de voyage ne se limitent pas à un seul aspect. Elles vous permettent d'apprendre, de travailler et de vous immerger pleinement dans la vie allemande.",
  },
  {
    id: 4,
    title: 'Élargissement des Horizons',
    description:
      "Vivre à l'étranger élargit votre perspective et renforce votre capacité à évoluer dans un environnement international.",
  },
  {
    id: 5,
    title: 'Apprentissage Linguistique Intégré',
    description:
      'Immergez-vous dans la langue allemande au quotidien, ce qui accélérera considérablement votre apprentissage.',
  },
]
