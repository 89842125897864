import React from 'react'
import { ThreeDotsVertical } from 'react-bootstrap-icons'
import CoursesPicture from '../../assets/img/sfz-cours.png'

import Sidebar from '../../components/Sidebar'
import FooterComponent from '../../components/Footers'
import ScrollToTop from '../../components/ScrollToTop'
import NavbarSticky from '../../components/NavbarSticky'
import { germanCourses } from '../../data/courses/coursesData'
import { MainCard } from '../../components/Cards'
import ComingSoon from '../../components/ComingSoon'
import SectionItemOne from '../../components/Sections/SectionItemOne'
import { Link } from 'react-router-dom'

export function loader({ params }) {}

const { highlights } = germanCourses.find(
  (course) => course.path === 'exam-preparation'
)

// const highlights = studyTripData?.highlights

const sectionItemsData = [
  {
    id: 'st-itemOne',
    title: 'Préparation Intensive et Ciblée',
    description: (
      <>
        Nos cours de préparation aux examens sont conçus pour vous donner les
        compétences et la confiance nécessaires pour affronter les examens
        officiels de langue allemande, tels que le{' '}
        <strong>
          <Link
            to={'https://testdaf.de/'}
            className="inline-link"
            target="blank"
          >
            TestDaF
          </Link>
        </strong>
        , le
        <strong>
          {' '}
          <Link
            to={'https://goethe.de/ins/de/fr/prf/prf.html'}
            className="inline-link"
            target="blank"
          >
            Goethe-Zertifikat
          </Link>
        </strong>
        , le{' '}
        <strong>
          <Link to={'https://telc.net/'} className="inline-link" target="blank">
            Telc
          </Link>
        </strong>
        , et bien d'autres. Vous bénéficierez d'une
        <strong> préparation intensive et ciblée</strong> sur les domaines clés
        de l'examen.
      </>
    ),
  },
  {
    id: 'st-itemTwo',
    title: 'Maîtrise des Compétences Clés',
    description: (
      <>
        Nos instructeurs expérimentés vous guideront à travers les différentes
        sections de l'examen, en vous aidant à améliorer votre{' '}
        <strong>compréhension orale et écrite</strong>, votre{' '}
        <strong>expression orale et écrite</strong>, ainsi que vos compétences
        en <strong>grammaire</strong> et en <strong>vocabulaire</strong>. <br />
        <br />
        Vous apprendrez des stratégies efficaces pour répondre aux questions et
        pour gérer le temps de manière optimale.
      </>
    ),
  },
  {
    id: 'st-itemThree',
    title: 'Pratique Réaliste',
    description: (
      <>
        Nos cours de préparation incluent des{' '}
        <strong>simulations d'examen régulières</strong>
        pour vous familiariser avec le format et les exigences de l'examen réel.{' '}
        <br />
        <br />
        Vous aurez l'occasion de pratiquer sous des{' '}
        <strong>conditions similaires à celles de l'examen</strong>, ce qui
        renforcera votre confiance et votre préparation.
      </>
    ),
  },
  {
    id: 'st-itemFour',
    title: 'Soutien Personnalisé',
    description: (
      <>
        Nous croyons en l'importance du <strong>soutien individuel</strong>. Nos
        instructeurs fourniront un feedback détaillé sur vos performances,
        identifieront vos points forts et vos domaines à améliorer, et
        travailleront avec vous pour{' '}
        <strong>maximiser vos chances de réussite</strong>.
      </>
    ),
  },
  {
    id: 'st-itemFive',
    title: 'Pourquoi Choisir Nos Cours de Préparation aux Examens ?',
    children: highlights ? (
      highlights.map((detail) => {
        return (
          <div
            key={`trip-${detail.id}`}
            className="card__container my-2 col-md-6 col-12"
          >
            <MainCard title={detail.title} text={detail.description} />
          </div>
        )
      })
    ) : (
      <ComingSoon />
    ),
  },
]

function Exampreparation() {
  const handleSidebarShow = () => {
    document.getElementById('mainSidebar').style.left = '00px'
    document.getElementById('contentWrapper').style.left = '300px'
    document.getElementById('sidebarButton').style.display = 'none'
    document.getElementById('wrapperOverlay').style.display = 'block'
    document.body.style.overflow = 'hidden'
  }

  return (
    <>
      <ScrollToTop>
        <Sidebar mobile={true} />
        <main className="main-wrapper" id="contentWrapper">
          <div className="overlay" id="wrapperOverlay"></div>
          <NavbarSticky />
          <header
            id="exampreparationHeader"
            className="blendmode-header main-header courses-header"
          >
            <div className="blendmode-header__img">
              <img
                src={CoursesPicture}
                alt="En cours"
                className="img-fluid item-image-img"
              />
              <div className="img__blendmode" />
            </div>
            <div className="blendmode-header__body">
              <div className="blendmode-header__body--title">
                <h1 className="blendmode-header__body--title-h1 h1 courses--detail__main-title">
                  Préparez-vous à Réussir avec <br />
                  Nos Cours de Préparation aux Examens de Langue Allemande
                </h1>
              </div>
            </div>
          </header>

          <section className="sections__wrapper py-4" id="coursesSection">
            <div className="sections__container container-lg container-fluid">
              <Sidebar />
              <div
                className="sidebar__button link__btn--secondary"
                id="sidebarButton"
              >
                <ThreeDotsVertical
                  className="sidebar__button--show"
                  onClick={handleSidebarShow}
                />
              </div>

              {/*  -- 
              -----------------------------------------
              COURSE DETAILS SECTION
              -----------------------------------------
              -- */}

              <div className="course-details px-4" id="courseDetails" data-spy>
                <div className="section-header">
                  <h2 className="section__title section--h2 h2">
                    Nos cours de Prépare aux examens
                  </h2>
                  <p className="section__description text-center text-lg-start">
                    Chez <strong>SFZ</strong>, nous comprenons l'importance des
                    examens officiels de langue pour atteindre vos objectifs
                    académiques, professionnels ou personnels. <br />
                    C'est pourquoi nous offrons des{' '}
                    <strong>cours de préparation</strong> spécialement conçus
                    pour vous aider à réussir ces défis linguistiques. <br />
                    <br />
                    Voici comment nos{' '}
                    <strong>cours de préparation aux examens</strong> peuvent
                    vous propulser vers le succès :
                  </p>
                </div>

                <div className="section__body">
                  {sectionItemsData.map((item) => (
                    <SectionItemOne
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      description={item.description}
                    >
                      {item.children && (
                        <div className="course justify-content-center justify-content-lg-between">
                          {item.children}
                        </div>
                      )}
                    </SectionItemOne>
                  ))}
                </div>

                <div className="section__footer">
                  <div className="section__footer--container container-md container-fluid">
                    <p className="section__footer--text text-highlighted">
                      Rejoignez-nous pour une préparation réussie aux examens de
                      langue allemande. Nos cours spécialisés vous aideront à
                      maîtriser les compétences nécessaires pour obtenir des
                      résultats exceptionnels. <br />
                      <br />
                      <strong>
                        Embarquez dans une Aventure d'Apprentissage et de
                        Découverte en Allemagne
                      </strong>
                    </p>
                    <div className="section__footer--link">
                      <Link to={'contacts'} className="link__btn--primary">
                        Inscrivez-vous dès Aujourd'hui !
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent />
        </main>
      </ScrollToTop>
    </>
  )
}

export default Exampreparation
