import * as React from 'react'

const SvgBusiness = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="family"
    className="svg-inline--fa fa-family fa-2x "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 80"
    {...props}
  >
    <g data-name="business">
      <path
        fill="currentColor"
        d="M60 15.05h9.43a11.87 11.87 0 0 1 2.6.24 9.92 9.92 0 0 1 8 9.54c.09 1.74 0 3.49 0 5.14l-7.43.32v-4.91c0-1.94-.95-2.85-2.91-2.87H9.71a2.39 2.39 0 0 0-2.22 2.29 5.07 5.07 0 0 0 0 .55v14.7h48.7c-.2.33-.34.54-.47.75-1.12 1.92-2.28 3.81-3.33 5.77-.37.69-.71 1.12-1.54 1a4.56 4.56 0 0 0-.85 0v1.68a13 13 0 0 1-.26 2.51A4.37 4.37 0 0 1 45.53 55c-3.68.06-7.37.06-11.05 0a4.55 4.55 0 0 1-4.38-4.4c-.05-1 0-1.95 0-3H7.46v16.85c0 2.31.86 3.16 3.17 3.16h40.38a1.2 1.2 0 0 1 1.25.78c1.07 2 2.25 3.94 3.4 5.9.13.22.27.43.47.74H10.44A10.17 10.17 0 0 1 0 64.71V25.44a10.07 10.07 0 0 1 10.4-10.39h9.66v-4.42A10.43 10.43 0 0 1 21 5.92 9.79 9.79 0 0 1 28.89.15 20 20 0 0 1 31.58 0h17.81A10.21 10.21 0 0 1 60 10.42zM52.49 15a2 2 0 0 0 .07-.33v-4.13c0-2.17-.92-3.08-3.1-3.09H30.4c-1.94 0-2.88 1-2.9 2.87v4.63z"
      />
      <path
        fill="currentColor"
        d="M77.56 35.1c12.77.31 22.44 9.56 22.44 22.47S90.5 80 77.59 80s-22.34-9.32-22.41-22.36c-.07-12.7 9.32-22.14 22.38-22.54zM80.14 55v-1.16c0-2.17 0-4.35-.1-6.51a2.27 2.27 0 0 0-2.54-2.27 2.44 2.44 0 0 0-2.42 2.54v9.94a2.37 2.37 0 0 0 2.49 2.54c2.54.06 5.09.09 7.63 0a2.47 2.47 0 0 0 2.06-3.8A2.7 2.7 0 0 0 84.67 55h-4.53z"
      />
    </g>
  </svg>
)
export default SvgBusiness
